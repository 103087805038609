export const Json = [
  {
    name: "Home",
    type: "single",
    icon: "si-cursor",
    routerLink: "",
  },
  {
    name: "Institucional",
    routerLink: "institutional",
    icon: "si-briefcase",
  },
  {
    name: "Lojas",
    routerLink: "stores",
    icon: "si-globe-alt",
  },
  {
    name: "3S Frotas",
    routerLink: "fleets",
    icon: "si-bag",
  },
  // {
  //   name: "3S Flex",
  //   routerLink: "flex",
  //   icon: "si-calendar",
  // },
  {
    name: "Contato",
    routerLink: "contacts",
    icon: "si-envelope",
  },
];
