import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";

export function InsertPayment(payment, response) {
  BaseService(
    {
      method: "POST",
      url: "payments",
      data: payment,
    },
    (res) => {
      return response(res);
    },
    (err) => {
      toast.error("Ocorreu um erro ao processar o pagamento");
    }
  );
}
