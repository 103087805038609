import React from "react";
import Logo from "./Logo";
import RecursiveMenu from "./RecursiveMenu";

export default function Nav(props) {
  return (
    <nav id="sidebar" aria-label="Main Navigation">
      <div className="bg-header-dark">
        <div className="content-header bg-white-10">
          <Logo style={{width : 200}} />
          <div>
            <a
              className="d-lg-none text-white ml-2"
              href="javascript:void(0)"
              onClick={() => props.toggleNav()}
            >
              <i className="fa fa-times-circle" />
            </a>
          </div>
        </div>
      </div>
      <div className="content-side content-side-full">
        <ul className="nav-main">
          <RecursiveMenu data={props.menu} />
        </ul>
      </div>
    </nav>
  );
}
