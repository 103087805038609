import React, { useEffect, useState } from "react";
import BaseService from "@/app/core/base/BaseService";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";

export default function ItineraryModal({ show, onHide }, props) {
  const [data, setData] = useState(Storage.getSOData());
  const [places, setPlaces] = useState([]);
  const [unityOriginId, setUnityOriginId] = useState(0);
  const [unityDropoutId, setUnityDropoutId] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (data) {
      setStartDate(data.startDate);
      setEndDate(data.endDate);
      setUnityOriginId(data.unityOriginId.id);
      setUnityDropoutId(data.unityDropoutId.id);
    }
    loadPlaces();
  }, [data]);

  function loadPlaces() {
    BaseService(
      {
        method: "GET",
        url: "unities/",
      },
      (res) => {
        setPlaces(res);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  function save() {
    if (startDate === null || endDate === null) {
      toast.error("Faltou responder a data de início e fim");
    } else if (unityOriginId === 0 || unityDropoutId === 0) {
      toast.error("Faltou responder o local de origem e destino");
    } else {
      let origin = places.filter((x) => x.id === parseInt(unityOriginId));
      let destiny = places.filter((x) => x.id === parseInt(unityDropoutId));

      let infos = { ...data, startDate, endDate };
      infos.unityDropoutId = destiny[0];
      infos.unityOriginId = origin[0];

      Storage.setSOData(infos);
      toast.info("Salvo com sucesso");
      onHide();
    }
  }

  return (
    <Modal
      title="Editar itinerário"
      size={"lg"}
      show={show}
      onHide={onHide}
      onSave={() => save()}
      onSubmit={() => save()}
    >
      <div className="block block-fx-pop">
        <div className="block-header">
          <div className="block-title">Origem</div>
        </div>
        <div className="block-content">
          <div className="form-group">
            <span>Onde você quer alugar?</span>
            <select
              className="form-control"
              onChange={(evt) => setUnityOriginId(evt.target.value)}
            >
              <option value={null}>Selecione</option>
              {places &&
                places.map((item) => (
                  <option
                    value={item.id}
                    selected={item.id === data.unityOriginId.id ? true : false}
                  >
                    {item.address}
                  </option>
                ))}
            </select>
          </div>

          <div className="form-group">
            <span>De quando?</span>
            <input
              className="form-control"
              onChange={(evt) => setStartDate(evt.target.value)}
              type="datetime-local"
              defaultValue={data && data.startDate}
            />
          </div>
        </div>
      </div>
      <div className="block block-fx-pop">
        <div className="block-header">
          <div className="block-title">Entrega</div>
        </div>
        <div className="block-content">
          <div className="form-group">
            <span>Onde você vai devolver o veículo?</span>
            <select
              className="form-control"
              onChange={(evt) => setUnityDropoutId(evt.target.value)}
            >
              <option value={null}>Selecione</option>
              {places &&
                places.map((item) => (
                  <option
                    value={item.id}
                    selected={item.id === data.unityDropoutId.id ? true : false}
                  >
                    {item.address}
                  </option>
                ))}
            </select>
          </div>

          <div className="form-group">
            <span>Até quando?</span>
            <input
              className="form-control"
              type="datetime-local"
              onChange={(evt) => setEndDate(evt.target.value)}
              defaultValue={data && data.endDate}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
