import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";

export function UpdateServiceOrder(infos, response) {
  BaseService(
    {
      method: "PUT",
      url: "service-orders",
      data: infos,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error("Ocorreu um erro ao processar o pagamento");
    }
  );
}
