import React, { useState, useEffect, useRef } from "react";
import BaseService from "@/app/core/base/BaseService";
import Storage from "@/app/core/util/Storage";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import DateTranslate from "@/app/core/util/DateTranslater";
import SOStatus from "@/app/core/util/StatusTranslater";
import { MoneyToString } from "@/app/core/util/MoneyHelper";
import ReservationModal from "../components/ReservationModal";
import CancelationModal from "../components/CancelationModal";

export default function MainComponent(props) {
  const [kickOut, setKickOut] = useState(false);
  const [data, setData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCancelation, setShowCancelation] = useState(false);
  const [selected, setSelected] = useState({});
  const [selectedCancelation, setSelectedCancelation] = useState({});
  const userData = Storage.getUserData();

  useEffect(() => {
    if (userData !== null) {
      loadUserHistoric();
    }
  }, []);

  function loadUserHistoric() {
    BaseService(
      {
        method: "GET",
        url: `service-orders/user/${userData.idToken.objectId}`,
      },
      (res) => {
        let response = [];
        console.log("infos", res);
        for (let s in res) {
          if (res[s].status !== 3) {
            response.push(res[s]);
          }
        }
        setData(response);
      },
      (err) => {
        toast.error(
          `Falha ao consultar o histórico, tente novamente mais tarde`
        );
      }
    );
  }

  if (kickOut) return <Redirect to="/" />;

  function openMaps(item) {
    window.open(`http://maps.google.com/?q=${item}`, "_blank");
  }

  function removeItem(item) {
    setSelectedCancelation(item);
    setShowCancelation(true);
  }

  function select(item) {
    setSelected(item);
    setShowModal(true);
  }

  function remove(item) {
    setSelectedCancelation(item);
    setShowCancelation(true);
  }

  return (
    <div>
      <div style={{ height: 140, backgroundColor: "#FF6600" }}></div>
      <div className="content">
        <div className="row py-3">
          <div className="col-md-12">
            <div
              className="py-4 py-md-0 text-center text-md-left js-appear-enabled animated fadeIn"
              data-toggle="appear"
            >
              <h1 className="font-size-h2 mb-2">Reservas</h1>
              <h3 className="font-size-lg font-w400 text-muted mb-0">
                Gerencie suas reservas e consulte seu histórico
              </h3>
            </div>
          </div>
          <div className="col-md-12 py-4">
            {data &&
              data.map((item) => (
                <div className="block block-bordered block-rounded">
                  <div className="block-header block-header-dafault">
                    <div className="block-title">
                      <div className="">
                        <small>Código da reserva</small>
                        <br />
                        {item.id}
                      </div>
                    </div>
                    <div className="block-tools">
                      <button
                        className="btn btn-sm btn-link text-dark"
                        onClick={() => select(item)}
                      >
                        <i className="fa fa-eye text-warning"></i>
                      </button>
                      <button
                        className="btn btn-sm btn-link text-dark"
                        onClick={() => remove(item)}
                      >
                        <i className="far fa-trash-alt text-danger"></i>
                      </button>
                    </div>
                  </div>
                  <div className="block-content">
                    <table className="table table-borderless table-hover">
                      <thead>
                        <tr>
                          <th>Data Retirada</th>
                          <th>Local de retirada</th>
                          <th>Data Devolução</th>
                          <th>Local de Devolução</th>
                          <th>Status da reserva</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={`serviceOrder-${item.id}`}>
                          <td>{DateTranslate(item.pickupDate)}</td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openMaps(
                                item.unityOrigin !== null
                                  ? item.unityOrigin.address
                                  : "S.I."
                              )
                            }
                          >
                            {item.unityOrigin !== null
                              ? item.unityOrigin.address
                              : "S.I."}
                          </td>
                          <td>{DateTranslate(item.dropoutDate)}</td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openMaps(
                                item.unityDropout !== null
                                  ? item.unityDropout.address
                                  : "S.I."
                              )
                            }
                          >
                            {item.unityDropout !== null
                              ? item.unityDropout.address
                              : "S.I."}
                          </td>
                          <td>{SOStatus(item.status)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ReservationModal
        show={showModal}
        infos={selected}
        onHide={() =>
          setShowModal(false) | setSelected({}) | loadUserHistoric()
        }
      />
      <CancelationModal
        show={showCancelation}
        infos={selectedCancelation}
        onHide={() =>
          setShowCancelation(false) |
          setSelectedCancelation({}) |
          loadUserHistoric()
        }
      />
    </div>
  );
}
