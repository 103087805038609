export default function SOStatus(sts) {
  switch (sts) {
    case 0:
      return "Configurando Pedido";
    case 1:
      return "Pendente";
    case 2:
      return "Em andamento";
    case 3:
      return "Cancelado";
    case 4:
      return "Concluído";
    case 5:
      return "Pagamento pendente";
    default:
      return "S.I.";
      break;
  }
}
