import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";

export function SendEmail(body, response) {
  BaseService(
    {
      method: "POST",
      url: "emails/send",
      data: body,
    },
    (res) => {
      return response(res);
    },
    (err) => {
      toast.error("Ocorreu um erro ao adicionar o cartão");
    }
  );
}
