import React, { useState } from "react";
import "./assets/nav.css";
import { Json } from "../config/menu";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Nav from "./components/Nav";
import Storage from "@/app/core/util/Storage";
import SideOverlay from "./components/SideOverlay";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

export default function Layout(props) {
  let pageContainerRef = React.createRef();

  const toggleOverlay = () => {
    const pageContainer = pageContainerRef.classList;
    pageContainer.remove("side-overlay-o");
    pageContainer.remove("sidebar-o");
  };
  const toggleNav = () => {
    const pageContainer = pageContainerRef.classList;
    if (window.innerWidth > 991) {
      pageContainer.remove("sidebar-o-xs");
      pageContainer.toggle("sidebar-o");
    } else {
      pageContainer.remove("sidebar-o");
      pageContainer.toggle("sidebar-o-xs");
    }
  };

  function loadfunction() {
    setTimeout(() => {
      window.hsConversationsSettings = {
        loadImmediately: false,
        inlineEmbedSelector: "#some-id",
        enableWidgetCookieBanner: true,
        disableAttachment: true,
      };

      /* ... */

      if (window.HubSpotConversations !== undefined) {
        window.HubSpotConversations.widget.load();
        window.HubSpotConversations.widget.close();
      }
    }, 4500);
  }

  return (
    <div
      ref={(el) => (pageContainerRef = el)}
      id="page-container"
      className={`enable-page-overlay side-scroll page-header-glass main-content-narrow sidebar-dark page-header-${Storage.getTheme()} `}
    >
      <div id="page-overlay" onClick={() => toggleOverlay()} />
      <Nav toggleNav={toggleNav} menu={Json} />
      <Header
        showModal={() => null /* LoginModal */}
        toggleNav={toggleNav}
        {...props.location}
      />
      <main id="main-container">{props.children}</main>
      <Footer />
      <SideOverlay style={{ width: "320px" }} title="Chat">
        {/* <Chat /> */}
      </SideOverlay>
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/8430934.js"
        ></script>
        <script type="text/javascript">{loadfunction()}</script>
      </Helmet>
      <ToastContainer className="toastr" />
    </div>
  );
}
