import { MsalAuthProvider, LoginType } from "react-aad-msal";
import GetBaseUrl from "../base/BaseAddress";

const tenant = "statustransporte.onmicrosoft.com";
const signInPolicy = "B2C_1_signup_signin";
const applicationID = "ae166c1f-919c-42e8-9d1b-5cd6b6ac6d63";
const reactRedirectUri = GetBaseUrl();

const tenantSubdomain = tenant.split(".")[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/tfp/`;
const signInAuthority = `${instance}${tenant}/${signInPolicy}`;

// Msal Configurations
const config = {
  auth: {
    authority: signInAuthority,
    clientId: applicationID,
    redirectUri: reactRedirectUri,
    validateAuthority: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [
    // "<property (i.e. user.read)>",
    // "https://statustransporte.onmicrosoft.com/status-web/",
    "https://status-transporte.azurewebsites.net/.auth/login/aad/callback",
    `https://${tenantSubdomain}.b2clogin.com/statustransporte.onmicrosoftonline.com/forgotPasswordPolicy`,
  ],
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin,
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
