import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";
import ReservationCard from "@/app/core/layouts/main-components/ReservationCard";
import DateTranslate, {
  DateTranslateToInput,
  Datediference,
} from "@/app/core/util/DateTranslater";
import { ProcessTotalValue } from "@/app/core/util/helpers/Payments";
import MapsComponent from "@/app/core/layouts/main-components/MapComponent";

export default function ReservedComponent(props) {
  const [kickOut, setKickOut] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [data, setData] = useState(Storage.getSOData());
  const userData = Storage.getUserData();

  const [days, setDays] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let date = DateTranslateToInput(data.startDate);
    let date2 = DateTranslateToInput(data.endDate);
    let res = Datediference(new Date(date), new Date(date2));
    setDays(res);
  }, []);

  useEffect(() => {
    let servicesList = data.services !== undefined ? data.services : [];
    let insurancesList = data.insurances !== undefined ? data.insurances : [];
    setTotal(ProcessTotalValue(data, servicesList, insurancesList));
  }, [data, days]);

  function goNext() {}

  function goReserves() {
    window.location = `#/reservations`;
  }

  function reloadData() {
    setData(Storage.getSOData());
  }

  function openMaps() {
    let url = `https://www.google.com/maps/place/${data.unityOriginId.address}/@${data.unityOriginId.latitude},${data.unityOriginId.longitude}`;

    window.open(url, "_blank");
  }

  if (redirect) return <Redirect to={`${redirect}`} />;
  if (kickOut) return <Redirect to="/" />;

  return (
    <div>
      <div className="row bg-warning" style={{ height: 205 }}>
        <div className="block-content">
          <div className="col-md-12 py-3 mt-5">
            <div className="btn-warning">
              <button className="btn btn-sm btn-square btn-warning">
                1. Itinerário
              </button>
              <button className="btn btn-sm btn-square btn-warning">
                2. Escolha seu veículo
              </button>
              <button className="btn btn-sm btn-square btn-warning">
                3. Proteção e opcionais
              </button>
              <button className="btn btn-sm btn-square btn-warning">
                4. Fechar reserva
              </button>
              <button className="btn btn-sm btn-square btn-warning">
                5. Reservado
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-md-9">
            <div className="block block-bordered">
              <div className="block-header">
                <h2 className="text-warning">
                  <i className="fa fa-check-circle" /> Reserva confirmada
                </h2>
              </div>
              <div className="block-content">
                <p>
                  Obrigado por reservar com a 3S. Seu código de reserva é{" "}
                  {data && `3S${data.id}`}
                  <br />
                  Enviamos todos os dados da reserva no e-mail{" "}
                  {userData &&
                    userData.idToken &&
                    userData.idToken.claims.emails[0]}
                </p>
              </div>
            </div>
            <div className="block block-bordered">
              <div className="block-header">
                <h4 className="text-dark">
                  <i className="fa fa-map-marker-alt" /> Seu itinerário
                </h4>
              </div>
              <div className="block-content">
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      <strong>
                        <i className="fa fa-map-marker-alt" /> Retirada
                      </strong>
                      <br />
                      <strong>
                        {data.unityOriginId && data.unityOriginId.city}
                      </strong>
                      <br />
                      {data.unityOriginId && data.unityOriginId.address}
                      <br />
                      <i className="far fa-calendar-alt" />{" "}
                      {DateTranslate(data.startDate)}
                      <br />
                      <i className="far fa-clock" /> {data.startTime}
                    </p>
                    <hr className="bg-warning" />
                    <p>
                      <strong>
                        <i className="fa fa-map-marker-alt" /> Devolução
                      </strong>
                      <br />
                      <strong>
                        {data.unityDropoutId && data.unityDropoutId.city}
                      </strong>
                      <br />
                      {data.unityDropoutId && data.unityDropoutId.address}
                      <br />
                      <i className="far fa-calendar-alt" />{" "}
                      {DateTranslate(data.endDate)}
                      <br />
                      <i className="far fa-clock" /> {data.endTime}
                    </p>
                    <button
                      className="btn btn-square btn-warning"
                      onClick={() => openMaps()}
                    >
                      Abrir no Google Maps
                    </button>
                    <button
                      className="btn btn-square btn-outline-warning"
                      onClick={() => goReserves()}
                    >
                      Minhas reservas
                    </button>
                  </div>
                  <div className="col-md-6">
                    <div style={{ height: 450 }}>
                      <MapsComponent
                        latitude={
                          data.unityOriginId && data.unityOriginId.latitude
                        }
                        longitude={
                          data.unityOriginId && data.unityOriginId.longitude
                        }
                        cameraZoom={14}
                        places={[data.unityOriginId]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <ReservationCard
              data={data}
              next={() => goNext()}
              reload={() => reloadData()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
