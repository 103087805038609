import React from "react";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import MainComponent from "./main/index";
import Layout from "@/app/modules/website/core/layouts";

const ProjectShell = (props) => (
  <Layout {...props}>{renderRoutes(props.route.routes, props)}</Layout>
);

const Redirecter = () => <Redirect to="/error/404" />;

export const ReservedRouter = {
  path: "/reserved",
  component: ProjectShell,
  routes: [
    {
      path: "/reserved",
      exact: true,
      component: MainComponent,
    },
    {
      path: "/reserved/search",
      exact: true,
      component: MainComponent,
    },
    {
      path: "*",
      component: Redirecter,
    },
  ],
};
