import React from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import GoogleDocsViewer from "react-google-docs-viewer";

export default function TermsAndConditionsModal({ show, onHide }, props) {
  function save() {
    onHide();
  }

  return (
    <Modal
      title="Termos"
      size={"lg"}
      show={show}
      onHide={onHide}
      onSave={() => save()}
      onSubmit={() => save()}
    >
      {/* <GoogleDocsViewer
        width="100%"
        height={680}
        fileUrl={
          "https://docs.google.com/document/d/e/2PACX-1vTMeeYyAoK7HwPysaEWPYHn1Qg9XfWUHS0xeQKs2G9TKgWjW6bGZX2S3upf9_MyzQwbMn0Pw4q8HvO6/pub"
        }
      /> */}
      <iframe
        width="100%"
        height={680}
        src="https://docs.google.com/document/d/e/2PACX-1vTMeeYyAoK7HwPysaEWPYHn1Qg9XfWUHS0xeQKs2G9TKgWjW6bGZX2S3upf9_MyzQwbMn0Pw4q8HvO6/pub?embedded=true"
      ></iframe>
    </Modal>
  );
}
