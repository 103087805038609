import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";

export function GetUnities(response) {
  BaseService(
    {
      method: "GET",
      url: "unities/",
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error("Erro consultar as unidades. Verifique a sua conexão");
    }
  );
}
