import React from "react";

export default function SideOverlay(props) {
  function toggleSideOverlay() {
    const ref = document.querySelector("#page-container").classList;
    ref.toggle("side-overlay-o");
  }
  return (
    <aside id="side-overlay" style={props.style}>
      <div className="bg-image">
        <div className="bg-primary-op">
          <div className="text-white new-header">
            {props.title}
            <span
              className="ml-auto text-white"
              style={{
                float: "right",
                marginRight: "0.5rem",
                cursor: "pointer"
              }}
              onClick={() => toggleSideOverlay()}
            >
              <i className="fa fa-times-circle" />
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "83vh",
          overflowY: "auto",
          overflowX: "hidden"
        }}
      >
        {props.children}
      </div>
    </aside>
  );
}
