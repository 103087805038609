import { GetGroups } from "@/app/core/base/controllers/GroupsController";
import BudgetComponent from "@/app/core/layouts/main-components/BudgetComponent";
import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function RentComponent(props) {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    loadGroups();
  }, []);

  function loadGroups() {
    GetGroups((res) => setGroups(res));
  }

  return (
    <div>
      <div style={{ height: 480, backgroundColor: "#FF6600" }}>
        <Carousel showArrows={true} autoPlay={true} background={"transparent "}>
          <div className="row bg-dark">
            <div className="col-md-6">
              <div className="content content-full content-top ml-5  text-left">
                <h1 className="font-w700 text-white mb-1 py-5">
                  Reduza custos e otimize o tempo da sua equipe
                </h1>
                <p className="py-0 text-white">
                  Terceirize sua frota e foque no core business do seu negócio
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content content-full content-top">
                <div className="py-5 mr-5">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/media/car.png"}
                    style={{ width: 454 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-md-12 text-center">
            <h3>Terceirização de frotas</h3>
            <h2>Otimize o tempo e a produtividade do seu negócio</h2>
            <h4>Por quê terceirizar sua frota com a 3S?</h4>
          </div>
        </div>
        <hr />
      </div>
      <div className="row content">
        <div class="col-md-4">
          <a
            class="block block-rounded block-link-shadow"
            href="javascript:void(0)"
          >
            <div class="block-content block-content-full ribbon ribbon-warning ribbon-modern">
              <div class="ribbon-box h2">+</div>
              <div class="py-3 text-center">
                <h2 className="fontw-900">Redução de custos</h2>
              </div>
            </div>
            <div class="block-content block-content-full block-content-sm text-center bg-body-light">
              <p class="text-dark">
                Reduza custos operacionais e foque somente no seu negócio
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-4">
          <a
            class="block block-rounded block-link-shadow"
            href="javascript:void(0)"
          >
            <div class="block-content block-content-full ribbon ribbon-warning ribbon-modern">
              <div class="ribbon-box h2">+</div>
              <div class="py-3 text-center">
                <h2 className="fontw-900">Gestão estratégica</h2>
              </div>
            </div>
            <div class="block-content block-content-full block-content-sm text-center bg-body-light">
              <p class="text-dark">
                Invista o tempo da sua equipe no core business de seu negócio,
                nós cuidamos do resto
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-4">
          <a
            class="block block-rounded block-link-shadow"
            href="javascript:void(0)"
          >
            <div class="block-content block-content-full ribbon ribbon-warning ribbon-modern">
              <div class="ribbon-box h2">+</div>
              <div class="py-3 text-center">
                <h2 className="fontw-900">Inovação e mobilidade</h2>
              </div>
            </div>
            <div class="block-content block-content-full block-content-sm text-center bg-body-light">
              <p class="text-dark">
                Antecipamos as necessidades dos nossos cliente, oferecendo
                soluções inovadoras em mobilidade
              </p>
            </div>
          </a>
        </div>
      </div>
      <div className="row bg-warning content">
        <div className="col-md-6">
          <h3 className="text-white">
            Veja como reduzir custos com a gestão da sua frota.
            <br />
            Solicite um orçamento
          </h3>
          <BudgetComponent />
        </div>
        <div className="col-md-6">
          <Carousel
            showArrows={true}
            autoPlay={true}
            className="bg-warning"
            // background={"transparent "}
          >
            {groups &&
              groups.map((item) => (
                <div className="row bg-warning">
                  <div className="col-md-12">
                    <h2 className="font-w700 text-white mb-1">
                      Frota {item.name}
                    </h2>
                    <div className="content content-full content-top">
                      <div className="mr-5">
                        <img
                          src={item.image && item.image.url}
                          style={{ width: 354 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
