import React, { useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { MoneyToString } from "@/app/core/util/MoneyHelper";
import BaseService from "@/app/core/base/BaseService";
import DateTranslate, {
  DateTranslateToTime,
  DateTranslateToInputDate,
} from "@/app/core/util/DateTranslater";

export default function PaymentItem({ data, reload }) {
  const inputFile = useRef(null);
  let typeOfUpload = 1;

  useEffect(() => {}, [data]);

  function onUpload(item) {
    inputFile.current.click();
    typeOfUpload = item;
  }

  function uploadFile(file) {
    if (file === null || file.length === 0) {
      return;
    }

    var formData = new FormData();
    formData.append("file", file);
    BaseService(
      {
        method: "POST",
        url: `files/`,
        data: formData,
        contentType: "multipart/form-data",
      },
      (res) => {
        let infos = data;
        if (typeOfUpload === 1) {
          infos.chargeId = res[0].id;
        } else {
          infos.receiptId = res[0].id;
        }
        updatePayment(infos);
      },
      (err) => {
        handleUploadErrors(err);
      }
    );
  }

  function updatePayment(infos) {
    BaseService(
      {
        method: "PUT",
        url: `payments/`,
        data: infos,
      },
      (res) => {
        reload();
      },
      (err) => {
        handleUploadErrors(err);
      }
    );
  }

  function remove() {
    if (window.confirm("Realmente deseja excluir esse item?")) {
      BaseService(
        {
          method: "DELETE",
          url: `payments/${data.id}`,
        },
        (res) => {
          reload();
        },
        (err) => {
          handleUploadErrors(err);
        }
      );
    }
  }

  function handleUploadErrors(err) {
    if (err.response.status === 500) {
      alert(`Erro interno de servidor`);
    } else if (err.response.status === 400) {
      alert(`Erro ao atualizar`);
    } else if (err.response.status === 401) {
      alert(`Upload não autorizado`);
    } else if (err.response.status === 403) {
      alert(`Acesso proibido`);
    } else if (err.response.status === 404) {
      alert(`A bike que esta tentando atualizar não existe`);
    } else if (err.response.status === 408) {
      alert(
        `Excedido tempo limite para o upload, verifique o status da sua conexão`
      );
    } else if (err.response.status === 413 || err.response.status === 414) {
      alert(`A imagem é grande demais`);
    } else if (err.response.status === 415) {
      alert(`Formato de arquivo não suportado`);
    } else {
      alert("Ocorreu um erro ao subir a imagem");
    }
  }

  function downloadCharge() {
    window.open(data.charge.url, "_blank");
  }

  function downloadReceipt() {
    window.open(data.receipt.url, "_blank");
  }

  return (
    <tr key={`${data && data.id}-payment`}>
      <td>{DateTranslate(data.paymentDate)}</td>
      <td>{data.description}</td>
      <td>R${MoneyToString(data.value, 2)}</td>
      <td>{data && data.paid === true ? "Pago" : "Pendente"}</td>
      <td>
        {data.charge !== null ? (
          <div>
            <button
              className="btn btn-sm btn-dual"
              onClick={() => downloadCharge()}
            >
              <i className="si si-cloud-download"></i> Baixar
            </button>
          </div>
        ) : (
          ""
        )}
      </td>
      <td>
        {data.receipt !== null ? (
          <div>
            <button
              className="btn btn-sm btn-dual"
              onClick={() => downloadReceipt()}
            >
              <i className="si si-cloud-download"></i> Baixar
            </button>
          </div>
        ) : (
          <label
            className="btn btn-success text-center"
            onClick={() => onUpload(2)}
          >
            Subir comprovante
          </label>
        )}
        <input
          type="file"
          style={{ visibility: "hidden", width: 2 }}
          ref={inputFile}
          key={`${data && data.id}-input`}
          onChange={(event) => {
            uploadFile(event.currentTarget.files[0]);
          }}
        />
      </td>
    </tr>
  );
}
