const Storage = {
  isAuthenticated: function (data) {
    const userData = Storage.getUserData();
    if (userData) return true;
    return false;
  },
  setUserData: function (data) {
    localStorage.setItem("user_data", JSON.stringify(data));
  },
  getUserData: function () {
    return JSON.parse(localStorage.getItem("user_data"));
  },
  removeUserData: function () {
    localStorage.removeItem("user_data");
  },
  setSOData: function (data) {
    localStorage.setItem("so_data", JSON.stringify(data));
  },
  getSOData: function () {
    return JSON.parse(localStorage.getItem("so_data"));
  },
  getTheme: function () {
    return localStorage.getItem("theme");
  },
  setTheme: function (color) {
    localStorage.setItem("theme", color);
  },
  getFeatures: function () {
    const features = JSON.stringify(localStorage.getItem("features"));

    if (typeof features === "object") {
      return features;
    }
    return [];
  },
};

export default Storage;
