import React, { useEffect, useState } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import { UpdateServiceOrder } from "@/app/core/base/controllers/ServiceOrderController";
import { SendEmail } from "@/app/core/base/controllers/EmailController";

export default function CancelationModal({ show, onHide, infos }, props) {
  const [data, setData] = useState({});
  const [response, setResponse] = useState({});
  const userData = Storage.getUserData();

  useEffect(() => {
    if (infos !== undefined && infos !== null) {
      setData(infos);
    }
  }, [infos]);

  function save() {
    UpdateServiceOrder({ ...data, status: 3 }, (res) => {
      const user = userData.idToken.claims;
      let message = `Reserva cancelada<br/>
        <br/>
        Cod. da reserva : ${data.id} <br/>
        Email do cliente : ${user.emails[0]} <br/>
        Motivo : ${response.motive} <br/>
        Mensagem : ${response.message} <br/>
        `;
      let body = {
        subject: "Reserva cancelada",
        targetEmail: "empresa3s@hotmail.com",
        targetName: "3S aluguéis",
        plainTextContent: message,
        htmlContent: message,
      };

      SendEmail(body, (res) => {
        toast.info("Sua reserva foi cancelada. Obrigado pelo feedback!");
        onHide();
      });
    });
  }

  function handleChange(evt) {
    let oldInfo = response;
    let newInfo = { [evt.target.name]: evt.target.value };
    setResponse({ ...oldInfo, ...newInfo });
  }

  return (
    <Modal
      title="Cancelar a Reserva?"
      size={"md"}
      show={show}
      onHide={onHide}
      onSave={() => save()}
      onSubmit={() => save()}
    >
      <div className="form-group">
        <span>Que pena, pode nos informar o motivo?</span>
        <select
          className="form-control"
          name="motive"
          onChange={(evt) => handleChange(evt)}
        >
          <option>Selecione</option>
          <option>Minha data/destino de viagem foi alterada</option>
          <option>Cancelei minha viagem/compromisso</option>
          <option>Tenho outra reserva no mesmo período</option>
          <option>Outro</option>
        </select>
      </div>

      <div className="form-group">
        <span>Deixe seu comentário</span>
        <textarea
          className="form-control"
          placeholder="Opcional"
          name="observation"
          onChange={(evt) => handleChange(evt)}
        />
      </div>
    </Modal>
  );
}
