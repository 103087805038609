import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import "../assets/footer.css";
import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";

export default function Footer() {
  const [contacts, setContacts] = useState(null);
  const [unities, setUnities] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    loadContacts();
    loadUnities();
  }, []);

  function loadContacts() {
    BaseService(
      {
        method: "GET",
        url: "contacts/",
      },
      (res) => {
        let item = res.filter((x) => x.level === 0);
        setContacts(item);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  function loadUnities() {
    BaseService(
      {
        method: "GET",
        url: "unities/",
      },
      (res) => {
        let item = res.filter((x) => x.matriz === true);
        setUnities(item);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  function saveNewsletter() {
    if (email === "" || email === null) {
      toast.error("Escreva seu email para receber novidades");
      return;
    }

    BaseService(
      {
        method: "POST",
        url: `newsletter/${email}`,
      },
      (res) => {
        toast.info("Obrigado por assinar nossa newsletter");
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  return (
    <footer
      id="page-footer"
      className={`bg-dark text-body-light`}
      style={{ width: "100%" }}
    >
      <div className="content py-0">
        <div className="row">
          {unities && unities.length > 0 ? (
            <div className="col-md-4">
              <div className="block-content text-white">
                <h4 className="text-white">Sede administrativa</h4>
                <p className="font-w300 text-gray-light">{`${unities[0].city} - ${unities[0].state}`}</p>
              </div>
            </div>
          ) : (
            <div className="col-md-4">
              <div className="block-content text-white">
                <h4 className="text-white">Sede administrativa</h4>
                <p className="font-w300 text-gray-light">
                  Rua Thomé de Souza, 648
                  <br />
                  Aviação - Praia grande
                  <br />
                  Cep: 11.702-840
                  <br />
                </p>
              </div>
            </div>
          )}
          {contacts && contacts !== null ? (
            <div className="col-md-4">
              <div className="block-content text-white">
                <h4 className="text-white">Contatos</h4>
                <label>{contacts[0].name}</label>
                <p className="font-w300 text-gray-light">
                  Telefone : {contacts[0].phone}
                  <br />
                  Celular : (13) 98111-3545
                  <br />
                  Email : {contacts[0].email}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-md-4">
            <div className="block-content text-white">
              <h4 className="text-white">Receba nossas ofertas</h4>
              <div className="form-group">
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={(evt) => setEmail(evt.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={() => saveNewsletter()}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
              <h4 className="text-white">Siga-nos nas redes sociais</h4>
              <div>
                <button
                  type="button"
                  className="btn btn-square btn-hero-primary"
                >
                  <i className="fab fa-facebook-f"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-square btn-hero-primary"
                >
                  <i className="fab fa-instagram"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12"></div>
        </div>
        <div className="row py-3">
          <div className="col-sm-6 text-left text-gray-dark">
            &copy; 3S - Todos os direitos reservados
          </div>
          <div className="col-sm-6 text-right">
            <a
              className="font-w600 text-dark text-gray-dark"
              href="https://navigate.one/"
              target="_blank"
            >
              Desenvolvido pela Navigate
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
