import React, { useEffect, useState } from "react";
import DateTranslate, {
  DateTranslateToInput,
  Datediference,
  DateSampleTranslate,
} from "../../util/DateTranslater";
import { ChargeTypeTransate } from "../../util/GeneralTranslater";
import { ProcessTotalValue } from "../../util/helpers/Payments";
import { MoneyToString } from "../../util/MoneyHelper";
import ItineraryModal from "./ItineraryModal";

export default function ReservationCard(
  { data, next, reload, enableNext },
  props
) {
  const [days, setDays] = useState(0);
  const [dailyValue, setDailyValue] = useState(0);
  const [total, setTotal] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [granularity, setGranularity] = useState("");
  const [prices, setPrices] = useState(false);

  useEffect(() => {
    let date = DateTranslateToInput(data.startDate);
    let date2 = DateTranslateToInput(data.endDate);
    let dd = Datediference(new Date(date), new Date(date2));
    console.log("days", dd);
    setDays(dd);

    if (data.group !== undefined) {
      let servicesList = data.services !== undefined ? data.services : [];
      let insurancesList = data.insurances !== undefined ? data.insurances : [];
      let result = ProcessTotalValue(data, servicesList, insurancesList, dd);
      setTotal(result);
      setDailyValue(days * data.group.dailyPrice);
      console.log("calculo", result, dd);
      if (dd < 30) {
        const calculo = result / dd;
        setGranularity(`* R$${MoneyToString(calculo, 2)} / Dia`);
      } else {
        const calculo = result / (dd / 30);
        setGranularity(`* R$${MoneyToString(calculo, 2)} / Mês`);
      }
    }
  }, [data]);

  useEffect(() => {
    let value = 0;
    let extras = 0;
    if (data.services !== undefined) {
      for (let s in data.services) {
        extras = extras + parseFloat(data.services[s].price);
      }
    }

    if (data.insurances !== undefined) {
      for (let s in data.insurances) {
        extras = extras + parseFloat(data.insurances[s].price);
      }
    }

    let fixedValues = 0;

    let servicesAndInsurances = plusServices();

    const sv = data.services;
    const ins = data.insurances;

    if (sv !== undefined) {
      let svRes = sv.filter((x) => x.chargeType === 2);
      if (svRes.length > 0) {
        for (let s in svRes) {
          fixedValues += svRes[s].price;
        }
      }
    }

    if (ins !== undefined) {
      let insRes = ins.filter((x) => x.chargeType === 2);
      if (insRes.length > 0) {
        for (let s in insRes) {
          fixedValues += insRes[s].price;
        }
      }
    }

    if (data.group !== undefined) {
      // if (days < 30) {
      //   let d = (parseInt(days) * parseFloat(data.group.dailyPrice)).toFixed(2);
      //   value = value + parseFloat(d);
      //   setGranularity(
      //     `* R$${MoneyToString(
      //       data.group.dailyPrice +
      //         (servicesAndInsurances + fixedValues) / days,
      //       2
      //     )} / Dia`
      //   );
      // } else {
      //   value = total;
      //   setGranularity(
      //     `* R$${MoneyToString(
      //       (data.group.monthlyPrice + servicesAndInsurances + fixedValues, 2)
      //     )} / Mês`
      //   );
      // }
    }
    console.log("Valor", value, extras);
    // setTotal(parseFloat(value) + parseFloat(extras));
  }, [data, days]);

  function plusServices() {
    if (data && data.services) {
      let v = 0;
      const sv = data.services;
      const ins = data.insurances;

      for (let s in sv) {
        if (sv[s].chargeType === 0) {
          v += sv[s].price * days;
        } else if (sv[s].chargeType === 1) {
          v += sv[s].price;
        }
      }

      for (let s in ins) {
        if (ins[s].chargeType === 0) {
          v += ins[s].price * days;
        } else if (ins[s].chargeType === 1) {
          v += ins[s].price;
        }
      }
      return v;
    } else {
      return 0;
    }
  }

  function reloadInfos() {
    reload();
  }

  function goNext() {
    next();
  }

  function loadDaily() {
    if (data && data.group) {
      return (
        <div className="py-1">
          <div className="title py-1">
            {data.group.isDailyDistanceFree === true
              ? `Diárias com KM Livre`
              : `Diárias`}
          </div>
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <div>{`${days} X R$${data && data.group.dailyPrice}`}</div>
            <div>
              <strong>
                R${MoneyToString(days * data.group.dailyPrice, 2)}
              </strong>
            </div>
          </div>
        </div>
      );
    }
  }

  function loadMonthly() {
    if (data && data.group) {
      return (
        <div className="py-1">
          <div className="title py-1">{days} dias no plano Mensal</div>
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <div>{`${(days / 30).toFixed(2)} X R$${
              data && MoneyToString(data.group.monthlyPrice, 2)
            }`}</div>
            <div>
              <strong>
                R$
                {MoneyToString((days / 30) * data.group.monthlyPrice, 2)}
              </strong>
            </div>
          </div>
        </div>
      );
    }
  }

  function processValues() {
    if (data.group !== undefined && data.group !== null) {
      return (
        <div className="">
          <div className="block-header">
            <h3 className="block-title font-w700">Subtotal</h3>
          </div>
          <hr style={{ color: "#333" }} />
          {/* <div className="block-content"> */}
          {days < 30 ? loadDaily() : loadMonthly()}
          <div className="py-2">
            <div className="block-title font-w700">Serviços</div>
            <ul className="nav-main">
              {data && data.services !== undefined ? (
                data.services.map((item) => (
                  <li
                    className="nav-main-link"
                    style={{ margin: 0, padding: 0 }}
                  >
                    <div>{item.name} </div>
                    <div className="ml-1">
                      <strong>
                        {" "}
                        R${item.price.toFixed(2)}{" "}
                        {item.chargeType === 0 ? "/ dia" : "/ mês"}
                      </strong>
                    </div>
                  </li>
                ))
              ) : (
                <li className="nav-main-link">Nenhum serviço selecionado</li>
              )}
            </ul>
            <div className="block-title font-w700">Seguro(s)</div>
            <ul className="nav-main">
              {data && data.insurances !== undefined ? (
                data.insurances.map((item) => (
                  <li className="nav-main-link">
                    <div>{item.name} </div>
                    <div className="ml-1">
                      <strong>
                        {" "}
                        R${item.price}/{ChargeTypeTransate(item.chargeType)}
                      </strong>
                    </div>
                  </li>
                ))
              ) : (
                <li className="nav-main-link">Nenhum seguro selecionado</li>
              )}
            </ul>
          </div>
          {/* </div> */}
        </div>
      );
    }
  }

  function loadSpecs() {
    return (
      <ul className="nav-main">
        {data.group &&
          data.group.vehicleOptionals.map((item) => (
            <li className="nav-main-link" style={{ margin: 0, padding: 0 }}>
              <button
                className="btn btn-sm btn-dual btn-sm mr-1"
                data-toggle="tooltip"
                data-placement="top"
                title={item.name}
              >
                <img src={item.icon && item.icon.url} style={{ height: 18 }} />
              </button>{" "}
              {item.name}
            </li>
          ))}
      </ul>
    );
  }

  function loadFranchise() {
    if (days < 30 && data.group.isDailyDistanceFree === true) {
      return "";
    }
    if (days < 30 && data.group.isDailyDistanceFree === false) {
      return (
        <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
          <div className="flex-sm-fill">
            <span className="font-w700">Franquia de KM mensal</span>
            <p>
              {data.group &&
                data.group.franchise &&
                data.group.franchise.franchise}
              KM
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
          <div className="flex-sm-fill">
            <span className="font-w700">Franquia de KM mensal</span>
            <p>
              {data.group &&
                data.group.franchise &&
                data.group.franchise.franchise}
              KM
            </p>
          </div>
        </div>
      );
    }
  }

  function loadExcedentDistance() {
    if (days > 29) {
      return (
        <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
          <div className="flex-sm-fill">
            <span className="font-w700">KM Excedente</span>
            <p>R${MoneyToString(data.group.monhtlyExceeded, 2)}</p>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  return (
    <div className="">
      {data && data.group !== undefined ? (
        <div className="text-center">
          <img
            src={
              data.group.image === undefined
                ? process.env.PUBLIC_URL + "/assets/media/car.png"
                : data.group.image.url
            }
            style={{ width: 224 }}
          />
          <h4>
            {data.group && data.group.name}
            <br />
            <small>{data.group && data.group.description}</small>
          </h4>
        </div>
      ) : (
        ""
      )}
      <div className="block block-bordered">
        <div className="block-content">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <div className="flex-sm-fill">
              <span className="font-w700">Período contratado</span>
              <p>
                {days && days > 30
                  ? `${Math.floor(days / 30)} Mês(es)`
                  : `${days} Dia(s)`}
              </p>
            </div>
          </div>
          {data && data.unityOriginId !== undefined ? (
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <div className="flex-sm-fill">
                <span className="font-w700">Local</span>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      `http://maps.google.com/?q=${
                        data && data.unityOriginId.address
                      }`,
                      "_blank"
                    )
                  }
                >
                  <i className="fa fa-map-marker-alt"></i>{" "}
                  {data && data.unityOriginId.address}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <div className="flex-sm-fill">
              <span className="font-w700">Data e hora de retirada</span>
              <p>
                <i className="far fa-calendar-check"></i>{" "}
                {data && DateSampleTranslate(data.startDate)} ás{" "}
                {data && data.startTime}
              </p>
            </div>
          </div>
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <div className="flex-sm-fill">
              <span className="font-w700">Data e hora de devolução</span>
              <p>
                <i className="far fa-calendar-check"></i>{" "}
                {data && DateSampleTranslate(data.endDate)} ás{" "}
                {data && data.endTime}
              </p>
            </div>
          </div>
          {data && data.group !== undefined ? loadFranchise() : ""}
          {data && data.group !== undefined ? loadExcedentDistance() : ""}
          {data && data.group !== undefined ? (
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <div className="flex-sm-fill">
                <span className="font-w700">Especificação</span>
                {loadSpecs()}
              </div>
            </div>
          ) : (
            ""
          )}
          {prices && prices === true ? processValues() : ""}
          <div className="block-header" style={{ padding: 0 }}>
            <div className="block-title">
              <h4 className="font-w700">Valor total</h4>
            </div>
            <div
              className="block-tools text-warning pb-3 "
              onClick={() => setPrices(!prices)}
            >
              Ver cálculo{" "}
              <i className={`si si-arrow-${prices === true ? "up" : "down"}`} />
            </div>
          </div>
          <div className="text-right">
            <h2 className="font-w700 text-right" style={{ color: "#58595b" }}>
              R${MoneyToString(total, 2)}
              <br />
              <small>{granularity}</small>
            </h2>
          </div>
        </div>
        <div className="p-3">
          {/* <button
            type="button"
            className="btn btn-square btn-outline-warning mt-auto"
            style={{ width: "100%", alignSelf: "bottom" }}
            onClick={() => setShowModal(true)}
          >
            Editar Itinerário
          </button> */}
          {enableNext && enableNext === true ? (
            <div className="p-0">
              <button
                type="button"
                className="btn btn-square btn-warning mt-auto"
                onClick={() => {
                  goNext();
                }}
                style={{ width: "100%", alignSelf: "bottom" }}
              >
                Avançar
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* {data && processValues()} */}
      <ItineraryModal
        show={showModal}
        onHide={() => setShowModal(false) | reloadInfos()}
      />
    </div>
  );
}
