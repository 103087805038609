import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MoneyToString } from "../../util/MoneyHelper";
import Storage from "@/app/core/util/Storage";
import { DateTranslateToInput, Datediference } from "../../util/DateTranslater";

export default function GroupCard({ data, selected, franchises }, props) {
  const [img, setImg] = useState(
    process.env.PUBLIC_URL + "/assets/media/car.png"
  );
  const [groupFranchises, setGroupFranchises] = useState([]);
  const [value, setValue] = useState(0);
  const [valueOff, setValueOff] = useState(0);
  const [franchise, setFranchise] = useState({});
  const [franchiseId, setFranchiseId] = useState(
    franchises[0] !== undefined ? franchises[0].id : 0
  );
  const [so, setSo] = useState(Storage.getSOData());
  const [days, setDays] = useState(0);

  useEffect(() => {
    if (data.image === null || data.image === undefined) {
      setImg(process.env.PUBLIC_URL + "/assets/media/car.png");
    } else {
      setImg(data.image.url);
    }

    if (franchise.length < 1 && franchises[0] !== undefined) {
      selectedFranchise(franchises[0].id);
    }

    loadGroupFranchises();

    loadDays();
  }, [data]);

  function loadDays() {
    let date = DateTranslateToInput(so.startDate);
    let date2 = DateTranslateToInput(so.endDate);
    let res = Datediference(new Date(date), new Date(date2));
    setDays(res);
    loadPrice(res);
  }

  function loadPrice(time) {
    if (time > 29) {
      setValue(data.monthlyPrice);
    } else {
      setValue(data.dailyPrice);
      const off = (data.dailyPrice * 3) / 100;
      setValueOff(data.dailyPrice - off);
    }
  }

  function loadGroupFranchises() {
    if (data.id !== undefined) {
      if (data.franchiseValues === undefined) {
        let infos = franchises;

        for (let s in infos) {
          const res = infos.filter((x) => x.id === infos[s].id);
          infos[s].franchise = res[0].value;
        }

        setGroupFranchises(franchises);
      } else {
        let infos = data.franchiseValues;
        console.log("infos", infos);
        for (let s in infos) {
          const res = franchises.filter((x) => x.id === infos[s].franchiseId);

          if (res.length > 0) {
            infos[s].franchise = res[0].value;
          }
        }

        if (data.franchiseValues.length === 1) {
          setFranchise(infos[0]);
        }
        setGroupFranchises(data.franchiseValues);
      }
    }
  }

  function selectedFranchise(id) {
    let res = groupFranchises.filter((x) => x.id === parseInt(id));
    setFranchiseId(parseInt(res[0].franchiseId));
    console.log("resultados", res, id);
    if (res.length > 0) {
      setValue(parseFloat(res[0].value));
      setFranchise(res[0]);
    } else {
      setValue(parseFloat(data.monthlyPrice));
    }
  }

  function select() {
    selected({
      ...data,
      monthlyPrice: value,
      franchiseId: franchiseId,
      franchise: franchise,
      payNow: false,
    });
  }

  function selectDaily(bool) {
    selected({
      ...data,
      monthlyPrice: value,
      dailyPrice: value,
      franchiseId: franchiseId,
      franchise: franchise,
      payNow: bool,
    });
  }

  return (
    <div
      className="block block-bordered block-themed"
      key={`group-${data && data.id}`}
    >
      <div className="block-content">
        <div className="row py-1">
          <div className="col-md-3 justify-content-sm-between align-items-sm-center">
            <img src={img} style={{ width: 224 }} />
          </div>
          <div className="col-md-4 p-3 pl-5">
            <div className="row">
              <div className="col-md-12 text-left">
                <h3 className="block-title font-w700">{data && data.name}</h3>
                {data && data.description}
              </div>
              <div className="col-md-12 text-left py-3">
                {data &&
                  data.vehicleOptionals.map((item) => (
                    <button
                      className="btn btn-sm btn-sm btn-dual mr-1"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={item.name}
                      onClick={() =>
                        toast.info(
                          `${item.name} - ${
                            item.description != null ? item.description : ""
                          }`
                        )
                      }
                    >
                      <img
                        src={item.icon && item.icon.url}
                        style={{ height: 18 }}
                      />
                    </button>
                  ))}
              </div>
              <div className="col-md-12 text-left">
                {data && data.isDailyDistanceFree === true ? (
                  <div>
                    <i className="si si-check text-warning"></i> Diária com KM
                    livre
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {days && days > 30 ? (
            <div className="col-md-5 ">
              <div className="block block-bordered p-3 mt-4">
                <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                  <div>
                    <h3 className="p-0" style={{ marginBottom: 0 }}>
                      <small>R$</small>
                      <strong className="font-weight-bold">
                        {value && MoneyToString(value, 2)} <small>/ mês</small>
                      </strong>
                    </h3>
                    <small>
                      R${data && MoneyToString(value / 30, 2)} / dia
                    </small>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-square btn-outline-warning mt-auto"
                      style={{ width: "100%", alignSelf: "bottom" }}
                      onClick={() => {
                        select();
                      }}
                    >
                      RESERVAR
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-4 py-2" style={{ padding: 0, margin: 0 }}>
                  Franquia Mensal
                </div>
                <div className="col-5" style={{ padding: 0, margin: 0 }}>
                  <select
                    className="form-control"
                    style={{ border: 0, borderBottom: "1px solid #333" }}
                    onChange={(evt) => selectedFranchise(evt.target.value)}
                  >
                    {groupFranchises &&
                      groupFranchises.map((item) => (
                        <option value={item.id}>{item.franchise} KM</option>
                      ))}
                  </select>
                </div>
                <div className="col-2 py-2" style={{ padding: 0, margin: 0 }}>
                  /mês
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-5 ">
              <div className="block block-bordered p-3 mt-4 ribbon ribbon-warning ribbon-left">
                <div class="ribbon-box">3% off</div>
                <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                  <div>
                    <br />
                    <h3 className="p-0" style={{ marginBottom: 0 }}>
                      <small>R$</small>
                      <strong className="font-weight-bold">
                        {valueOff && MoneyToString(valueOff, 2)}{" "}
                        <small>/ dia</small>
                      </strong>
                    </h3>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-square btn-warning mt-auto"
                      style={{ width: "100%", alignSelf: "bottom" }}
                      onClick={() => {
                        selectDaily(true);
                      }}
                    >
                      PAGAR AGORA
                    </button>
                  </div>
                </div>
              </div>
              <div className="block block-bordered p-3 mt-4">
                <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                  <div>
                    <h3 className="p-0" style={{ marginBottom: 0 }}>
                      <small>R$</small>
                      <strong className="font-weight-bold">
                        {value && MoneyToString(value, 2)} <small>/ dia</small>
                      </strong>
                    </h3>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-square btn-outline-warning mt-auto"
                      style={{ width: "100%", alignSelf: "bottom" }}
                      onClick={() => {
                        selectDaily(false);
                      }}
                    >
                      PAGAR DEPOIS
                    </button>
                  </div>
                </div>
              </div>
              {data && data.isDailyDistanceFree === false ? (
                <div className="form-group row">
                  <div className="col-4 py-2" style={{ padding: 0, margin: 0 }}>
                    Franquia Mensal
                  </div>
                  <div className="col-5" style={{ padding: 0, margin: 0 }}>
                    <select
                      className="form-control"
                      style={{ border: 0, borderBottom: "1px solid #333" }}
                      onChange={(evt) => selectedFranchise(evt.target.value)}
                    >
                      {groupFranchises &&
                        groupFranchises.map((item) => (
                          <option value={item.id}>{item.franchise} KM</option>
                        ))}
                    </select>
                  </div>
                  <div className="col-2 py-2" style={{ padding: 0, margin: 0 }}>
                    /mês
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
