import React, { useState, useEffect } from "react";

export default function StoreMarker({ click, data }, props) {
  return (
    <>
      <img
        src={process.env.PUBLIC_URL + "/assets/media/marker.png"}
        style={{ height: 28 }}
        onClick={() => click(data)}
      />
    </>
  );
}
