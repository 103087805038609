import React from "react";
import { HashRouter as Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import Error404Page from "@/app/core/layouts/errors/Error404Page";
import Storage from "@/app/core/util/Storage";
import { HomeRouter } from "./modules/website/home";
import { RentRouter } from "./modules/website/rent";
import { OptionalsRouter } from "./modules/website/optionals";
import { ClosureRouter } from "./modules/website/closure";
import { ContactsRouter } from "./modules/website/contacts";
import { AccountRouter } from "./modules/website/account";
import { ReservationsRouter } from "./modules/website/reservations";
import { StoresRouter } from "./modules/website/stores";
import { FleetsRouter } from "./modules/website/fleets";
import { InstitutionalRouter } from "./modules/website/institutional";
import { ReservedRouter } from "./modules/website/reserved";

export default function App() {
  if (!localStorage.getItem("theme")) {
    localStorage.setItem("theme", "light");
  }

  document.body.classList.add("bg-" + Storage.getTheme());

  const routes = [
    RentRouter,
    OptionalsRouter,
    AccountRouter,
    StoresRouter,
    InstitutionalRouter,
    ReservedRouter,
    ReservationsRouter,
    FleetsRouter,
    ContactsRouter,
    ClosureRouter,
    HomeRouter,
    {
      path: "*",
      component: Error404Page,
      onShell: false,
    },
  ];

  return (
    <Router>
      {renderRoutes(routes)}
      {/* <Route></Route> */}
    </Router>
  );
}
