import { MoneyToString } from "@/app/core/util/MoneyHelper";
import React, { useState } from "react";

export default function OptionalCard(
  { data, selected, selecteds, type },
  props
) {
  const [showDescription, setShowDescription] = useState(false);

  function change(sts) {
    let infos = { ...data, selected: sts };
    selected(infos);
  }

  return (
    <div
      className="block block-bordered block-themed"
      style={{ marginBottom: 0, marginTop: 0 }}
    >
      {/* <div className="block-header bg-warning">
        <h3 className="block-title">{data && data.name}</h3>
      </div> */}
      <div className="block-content">
        <div className="row py-3">
          <div className="col-md-1">
            {type === 1 ? (
              <i className="far fa-compass fa-2x text-warning"></i>
            ) : (
              <i className="fa fa-shield-alt fa-2x text-warning"></i>
            )}
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12 font-w700">
                {data && data.name}
                <br />
                <span
                  className="text-warning link"
                  onClick={() => setShowDescription(!showDescription)}
                >
                  Saiba mais
                </span>
              </div>
              {showDescription === true ? (
                <div className="col-md-12 font-w500">
                  <br />
                  {data && data.name}
                  <hr style={{ borderColor: "#333" }} />
                  {data && data.description}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-3">
            <h3 className="p-0" style={{ marginBottom: 0 }}>
              <div className="custom-control custom-checkbox custom-control-lg custom-control-inline">
                <label
                // className="custom-control-label"
                >
                  R${data && MoneyToString(data.price, 2)}
                </label>
                <input
                  type="checkbox"
                  className="text-warning ml-3"
                  style={{ height: 25, width: 25 }}
                  id={`opt-${data.id}-${type}`}
                  name={`opt-${data.id}-${type}`}
                  // checked={selecteds}
                  onChange={(evt) => change(evt.target.checked)}
                />
              </div>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
