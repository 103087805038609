import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { CurrentDate, CurrentTime } from "@/app/core/util/DateTranslater";

export default function RentCard(props) {
  const [unities, setUnities] = useState([]);
  const [unity, setUnity] = useState(null);
  const [query, setQuery] = useState("");
  const [startDate, setStartDate] = useState(CurrentDate());
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const userData = Storage.getUserData();

  useEffect(() => {
    loadUnities();
    loadDefaultTime();
  }, []);

  function loadDefaultTime() {
    const info = CurrentTime();
    setStartTime(info);
    setEndTime(info);
    console.log("tempo", info);
  }

  function loadUnities() {
    BaseService(
      {
        method: "GET",
        url: "unities/",
      },
      (res) => {
        setUnities(res);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  function missingFields() {
    let message = "";
    if (unity === null || unity === undefined) {
      message += " Local;";
    }
    if (startDate === null) {
      message += " Data de retirada;";
    }
    if (startTime === null) {
      message += " Hora de retirada;";
    }
    if (endTime === null) {
      message += " Hora de devolução;";
    }
    if (endDate === null) {
      message += " Data de devolução;";
    }

    return message;
  }

  function goRent() {
    if (userData === null) {
      alert("Faça login para continuar");
      return;
    }
    if (
      unity === null ||
      unity === undefined ||
      startDate === null ||
      endDate === null
    ) {
      toast.error(`Faltou preencher : ${missingFields()}`);
      return;
    } else {
      Storage.setSOData({
        unityOriginId: unity,
        unityDropoutId: unity,
        startDate,
        endDate,
        startTime,
        endTime,
      });

      setTimeout(() => {
        window.location = "#/rent";
      }, 300);
    }
  }

  return (
    <div className="">
      <div className="col-md-12 bg-black-50">
        <div className="block-content py-3">
          <div className="row">
            <div className="col-md-12">
              <h4 className="text-white">Alugue um carro</h4>
            </div>
            <div className="col-md-5">
              <span className="text-white">Local de Retirada</span>
              <Typeahead
                options={unities}
                labelKey={(option) => `${option.address} - ${option.city}`}
                onSearch={(q) => setQuery(q)}
                id="advanced-filter-state"
                name="name"
                placeholder="Onde você quer alugar?"
                onChange={(selected) => {
                  if (selected[0] === undefined) {
                    setUnity("");
                  } else {
                    setUnity(selected[0]);
                  }
                }}
              />
            </div>
            <div className="col-md-3">
              <span className="text-white">Data e hora da retirada</span>
              <div
                className="input-group form-control form-control-alt"
                style={{ padding: 0, margin: 0 }}
              >
                <div className="col-7" style={{ padding: 0, margin: 0 }}>
                  <input
                    type="date"
                    className="form-control form-control-alt"
                    defaultValue={startDate}
                    onChange={(evt) => setStartDate(evt.target.value)}
                  />
                </div>
                <div className="col-5" style={{ padding: 0, margin: 0 }}>
                  <input
                    type="time"
                    className="form-control form-control-alt"
                    value={startTime}
                    onChange={(evt) => setStartTime(evt.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <span className="text-white">Data e hora da devolução</span>
              <div
                className="input-group form-control form-control-alt"
                style={{ padding: 0, margin: 0 }}
              >
                <div className="col-7" style={{ padding: 0, margin: 0 }}>
                  <input
                    type="date"
                    className="form-control form-control-alt"
                    defaultValue={endDate}
                    onChange={(evt) => setEndDate(evt.target.value)}
                  />
                </div>
                <div className="col-5" style={{ padding: 0, margin: 0 }}>
                  <input
                    type="time"
                    className="form-control form-control-alt"
                    value={endTime}
                    onChange={(evt) => setEndTime(evt.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-1 d-flex">
              <button
                type="button"
                className="btn btn-square btn-secondary mt-auto"
                onClick={() => {
                  goRent();
                }}
                style={{ width: "100%", alignSelf: "bottom" }}
              >
                Buscar
                {/* <i className="si si-arrow-right"></i> */}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
