import React, { useEffect, useState } from "react";
import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-bootstrap";

export default function BannerComponent(props) {
  const [banners, setBanners] = useState(null);

  useEffect(() => {
    loadBanners();
  }, []);

  function loadBanners() {
    BaseService(
      {
        method: "GET",
        url: "banners/",
      },
      (res) => {
        setBanners(res);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  return (
    <div style={{ height: 590, backgroundColor: "#FF6600" }}>
      <Carousel showArrows={true} autoPlay={true} background={"transparent "}>
        {banners &&
          banners.map((item) => (
            <div className="row" style={{ backgroundColor: "#FF6600" }}>
              <div className="col-md-6">
                <div className="content content-full content-top ml-3  text-left">
                  <h1 className="font-w700 text-white mb-1 py-5">
                    {item.title}
                  </h1>
                  <p className="py-0 text-white pr-3">{item.description}</p>
                  {item && item.linkTo !== null ? (
                    <a
                      className="btn btn-sm btn-square btn-warning"
                      href={item.linkTo}
                    >
                      <i className="fa fa-plus text-white mr-1"></i> Saiba mais
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="content content-full content-top">
                  <div className="py-5 mr-3">
                    <img
                      src={item.image && item.image.url}
                      style={{ width: "90%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Carousel>
    </div>
  );
}
