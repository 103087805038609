import {
  Datediference,
  DateTranslateToInput,
  DateTranslateToInputDate,
} from "../DateTranslater";

export function ProcessPayments(serviceOrder, services, insurances) {
  let date = DateTranslateToInput(
    `${serviceOrder.startDate}T${serviceOrder.startTime}`
  );
  let date2 = DateTranslateToInput(
    `${serviceOrder.endDate}T${serviceOrder.endTime}`
  );
  const days = Datediference(new Date(date), new Date(date2));

  let payment = [];
  const servicesPrice = ServicesAndInsuranceTotal(services, days);
  const insurancesPrice = ServicesAndInsuranceTotal(insurances, days);

  if (days < 30) {
    const diaria = serviceOrder.group.dailyPrice * days;
    const total = diaria + servicesPrice + insurancesPrice;
    payment.push({
      paymentDate: date,
      value: total,
      paid: false,
      description: `Pagamento referente a ${days} diárias`,
    });
  } else {
    const months = Math.floor(days / 30);
    const monthly = serviceOrder.group.monthlyPrice * months;
    const total = monthly + servicesPrice + insurancesPrice;

    for (let s = 0; s < months; s++) {
      let when = new Date(date);
      const month = when.getMonth() + 1;
      const newDate = new Date(when.setMonth(month + s));
      let diff = Datediference(new Date(newDate), new Date(date2));
      if (diff > 30) {
        payment.push({
          paymentDate: DateTranslateToInputDate(newDate),
          value: total,
          paid: false,
          description: `Mensalidade ${s}`,
        });
      } else {
        if (newDate > date2) {
          payment.push({
            paymentDate: date2,
            value: total,
            paid: false,
            description: `Mensalidade ${s}`,
          });
        } else {
          payment.push({
            paymentDate: DateTranslateToInputDate(newDate),
            value: total,
            paid: false,
            description: `Mensalidade ${s}`,
          });

          if (diff > 0) {
            const diaria = serviceOrder.group.dailyPrice * diff;
            payment.push({
              paymentDate: DateTranslateToInputDate(newDate),
              value: diaria + servicesPrice + insurancesPrice,
              paid: false,
              description: `Diferença em dias ${diff}`,
            });
          }
        }
      }
    }
  }

  return payment;
}

export function ProcessTotalValue(serviceOrder, services, insurances, days) {
  // const date = DateTranslateToInput(
  //   `${serviceOrder.startDate} ${serviceOrder.startTime}`
  // );
  // const date2 = DateTranslateToInput(
  //   `${serviceOrder.endDate} ${serviceOrder.endTime}`
  // );
  // const days = Datediference(new Date(date), new Date(date2));

  // console.log("Processando", date, date2);

  const servicesPrice = ServicesAndInsuranceTotal(services, days);
  const insurancesPrice = ServicesAndInsuranceTotal(insurances, days);
  let total = 0;

  if (days < 30) {
    const diaria = serviceOrder.group.dailyPrice * days;
    total = diaria + servicesPrice + insurancesPrice;
  } else {
    const months = days / 30;
    const monthly = serviceOrder.group.monthlyPrice * months;
    total = monthly + servicesPrice + insurancesPrice;
  }

  console.log("Processando", days, servicesPrice, insurancesPrice, total);

  return total;
}

function ServicesAndInsuranceTotal(services, days) {
  let total = 0;
  let months = days > 30 ? days / 30 : 1;

  const sv = services;
  if (sv !== undefined) {
    let svSingle = sv.filter((x) => x.chargeType === 2);
    let svDaily = sv.filter((x) => x.chargeType === 0);
    let svMonthly = sv.filter((x) => x.chargeType === 1);
    if (svSingle.length > 0) {
      for (let s in svSingle) {
        total += svSingle[s].price;
      }
    }

    if (svDaily.length > 0) {
      for (let s in svDaily) {
        total += svDaily[s].price * days;
      }
    }

    if (svMonthly.length > 0) {
      for (let s in svMonthly) {
        total += svMonthly[s].price * months;
      }
    }
  }

  return total;
}
