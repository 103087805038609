export default function DateTranslate(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${day}/${month}/${year} ${hours}:${minutes}`;
  return formatted;
}

export function DateSampleTranslate(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${day}/${month}/${year}`;
  return formatted;
}

export function DateTranslateToInput(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${year}-${month}-${day}T${hours}:${minutes}`;
  return formatted;
}

export function DateTranslateToInputDate(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${year}-${month}-${day}`;
  return formatted;
}

export function DateTranslateToTime(dte) {
  const date = new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${hours}:${minutes}`;
  return formatted;
}

export function Datediference(first, second) {
  let i = Math.round((second - first) / (1000 * 60 * 60 * 24));
  console.log("Processando 0", first, second, i);
  return i;
}

export function CurrentTime() {
  const date = new Date();
  const currentTime = date.getHours() + ":" + date.getMinutes();

  return currentTime;
}

export function CurrentDate() {
  const date = new Date();
  const currentDate = date.toISOString().slice(0, 10);

  return currentDate;
}
