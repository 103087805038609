import React, { useState } from "react";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { SendEmail } from "../../base/controllers/EmailController";

export default function ContactComponent() {
  const [data, setData] = useState({});

  function handleChange(evt) {
    let infos = data;
    let newInfos = { [evt.target.name]: evt.target.value };

    setData({ ...infos, ...newInfos });
  }

  function send() {
    if (
      data.name === undefined ||
      data.email === undefined ||
      data.phone === undefined
    ) {
      toast.error("Os campos : nome, email e telefone são obrigatorios");
    } else {
      let message = `Solicitação de orçamento<br/>
            <br/>
            Nome : ${data.name} <br/>
            Email : ${data.email} <br/>
            Telefone : ${data.phone} <br/>
            Preferencia de contato : ${data.contactForm} <br/>
            Motivo : ${data.motive} <br/>
            Mensagem : ${data.message} <br/>
            `;
      let body = {
        subject: "Solicitação de orçamento",
        targetEmail: "empresa3s@hotmail.com",
        targetName: "3S aluguéis",
        plainTextContent: message,
        htmlContent: message,
      };

      SendEmail(body, (res) =>
        toast.success(
          "Mensagem enviada com sucesso! Entraremos em contato em breve"
        )
      );
    }
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <h3 className="text-dark">Contato</h3>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <span className="text-dark">Nome completo</span>
          <input
            type="text"
            className="form-control"
            name="name"
            onChange={(evt) => handleChange(evt)}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <span className="text-dark">Email</span>
          <input
            type="email"
            className="form-control"
            name="email"
            onChange={(evt) => handleChange(evt)}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <span className="text-dark">Celular / Whatsapp</span>
          <InputMask
            mask="(99) 99999-9999"
            maskChar=""
            type="text"
            className="form-control"
            id="advanced-filter-document"
            name="phone"
            placeholder="(__) _____-____"
            onChange={(event) => {
              handleChange({
                target: {
                  value: event.target.value,
                  name: "phone",
                },
              });
            }}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <span className="text-dark">Preferencia de contato por</span>
          <select
            className="form-control"
            name="contactForm"
            onChange={(evt) => handleChange(evt)}
          >
            <option>Selecione</option>
            <option>Whatsapp</option>
            <option>Email</option>
          </select>
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group">
          <span className="text-dark">Motivo</span>
          <input
            type="text"
            className="form-control"
            name="motive"
            onChange={(evt) => handleChange(evt)}
          />
        </div>
      </div>
      <div className="col-md-12">
        <div className="form-group">
          <span className="text-dark">Mensagem</span>
          <textarea
            type="text"
            className="form-control"
            name="message"
            onChange={(evt) => handleChange(evt)}
          />
        </div>
      </div>
      <div className="col-md-12">
        <button className="btn btn-square btn-danger" onClick={() => send()}>
          Enviar
        </button>
      </div>
    </div>
  );
}
