import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import BaseService from "@/app/core/base/BaseService";
import RentCard from "../../core/layouts/components/RentCard";
import ContactComponent from "@/app/core/layouts/main-components/ContactComponent";

export default function MainComponent(props) {
  const [contacts, setContacts] = useState(null);

  useEffect(() => {
    loadContacts();
  }, []);

  function loadContacts() {
    BaseService(
      {
        method: "GET",
        url: "contacts/",
      },
      (res) => {
        setContacts(res);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  return (
    <div>
      <div style={{ height: 300, backgroundColor: "#FF6600" }}>
        <div className="block-content">
          <div className="col-md-12 py-6">
            <RentCard />
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row py-3">
          <div className="col-md-6">
            <ContactComponent />
          </div>
          <div className="col-md-6">
            {contacts &&
              contacts.map((item) => (
                <div className="block block-fx-shadow">
                  <div className="block-content">
                    <table className="table table-bordered table-hover">
                      <tr>
                        <td style={{ width: 30 }}>
                          <i className="si si-pointer"></i>
                        </td>
                        <td>{item.name}</td>
                      </tr>
                      <tr>
                        <td style={{ width: 30 }}>
                          <i className="si si-call-in"></i>
                        </td>
                        <td>{item.phone}</td>
                      </tr>
                      <tr>
                        <td style={{ width: 30 }}>
                          <i className="si si-envelope-letter"></i>
                        </td>
                        <td>{item.email}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
