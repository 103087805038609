import { SendEmail } from "../../base/controllers/EmailController";
import { DateSampleTranslate } from "../DateTranslater";
import { MoneyToString } from "../MoneyHelper";

export function ReservationEmail(data, userData) {
  const user = userData.idToken.claims;
  let message = `<html lan='pt-BR'>
    <head>
      <title>Recibo-3S</title>
      <meta charset='utf-8'/>
      <style>
            body {
            max-width: 610px;
            margin: auto;
            font-family: Microsoft New Tai Lue;
            }
            .navibar{
            }
            table {
            padding: 20px;
            }
            .bordered {
                border-bottom: 2px solid #ed6a1a;
            }
            .bordered2 {
                border-bottom: 1px solid #ed6a1a;
            }
            table{
                width: 100%;
            }
            th{ 
            text-align: right;
            }
            .fundo-topo{
                background-image: url('https://3salugueis.blob.core.windows.net/files/website/3s-bg.png'); 
                background-repeat: no-repeat;
                z-index: 0;
                height: 100%;
            }
            .container{
                width: 610px;
                height:  390px;
            }
            .container2{
                width: 610px;
                height:  250px;
                background-color: rgb(255, 247, 240);
            }
            .container3{
                width: 610px;
                height:  150px;
                background-color:  #ed6a1a ;
            }
            .center{
                text-align: center;
            }
            .linha-vertical {
            height: 100px;/*Altura da linha*/
            border-left: 2px solid;/* Adiciona borda esquerda na div como ser fosse uma linha.*/
            color: #ed6a1a; 
            margin: 10px;
            margin-top: -10px;
            margin-left: 13px;
            }
            .data-hora-valor{

            text-align: right;
            padding: 5px;
            }
            .row{  
                width: 600px;
            }
            .agradecer{

                text-align: center;
                color: white;
            }
            h1,h2,h3,h4,h5,h6 {
                font-weight: 300;
                line-height: inherit;
                letter-spacing: -1px;
            }

            h1 {
                color: #292929;
                font-size: 3em;
                margin-bottom: 30px;
            }

            h2 {
                color: #000;
                font-size: 2em;
            }

            h3 {
                color: #fff;
                font-size: 1.5em;
                font-weight: 500;
                margin-bottom: 0;
            }

            h4 {
                color: #696969;
                font-size: 18px;
                line-height: normal;
            }

            p {
                color: #757575;
                font-size: 14px;
                font-weight: normal;
                line-height: 25px;
            }

            strong {
                font-weight: 500;
            }</style>
    </head>
    <body>
       <section class='navbar container custom-navbar navbar-fixed-top' role='navigation'>
            <div class='fundo-topo'>
                <div class='row'>
                <div class='data-hora-valor'>
                    <h3>
                    <span style='color: white;'><br><small>Total: </small></span><b>R$${MoneyToString(
                      data.value,
                      2
                    )}</b></h3>
                </div>
                </div>
                <h2 style='text-align: center; color: white;'>Obrigado por escolher<br>a 3S ${
                  user.given_name
                }</h2>
            </div>
            </section>
            <!--        Valores-->
            <section>
            <div>
                <table style='width : 610px;'>
                <tr>
                    <td>
                    <h2 class=''>Cliente:</h2>
                    </td>
                </tr>
                <tr>
                    <td>
                    <h3 class='bordered2' style='color: black;'>Nome</h3>
                    </td>
                    <th>
                    <h3 class='bordered2' style='color: black; '>${
                      user.given_name
                    } ${user.family_name}</h3>
                    </th>
                </tr>
                <tr>
                <td>
                    <h4 class='bordered2'>Email:</h4>
                    </td>
                    <th>
                    <h4 class='bordered2'>${user.emails[0]}</h4>
                    </th>
                </tr>
                </table>
            </div>
            </section>

            <section>
            <div>
                <table style='width : 610px;'>
                <tr>
                    <td>
                    <h2 class=''>Detalhes:</h2>
                    </td>
                </tr>
                <tr>
                    <td>
                    <h2 class='bordered'>Total:</h2>
                    </td>
                    <th>
                    <h2 class='bordered'>R$${MoneyToString(data.value, 2)}</h2>
                    </th>
                </tr>
                <tr>
                    <td>
                    <h3 class='bordered2' style='color: black;'>Grupo</h3>
                    </td>
                    <th>
                    <h3 class='bordered2' style='color: black; '>${
                      data.group.name
                    }</h3>
                    </th>
                </tr>
                    <tr>
                <td>
                    <h4 class='bordered2'>Retirada:</h4>
                    </td>
                    <th>
                    <h4 class='bordered2'>${DateSampleTranslate(
                      data.startDate
                    )} ás ${data.startTime}</h4>
                    </th>
                </tr>
                <tr>
                <td>
                    <h4 class='bordered2'>Devolução:</h4>
                    </td>
                    <th>
                    <h4 class='bordered2'>${DateSampleTranslate(
                      data.endDate
                    )} ás ${data.endTime}</h4>
                    </th>
                </tr>                
                </table>
            </div>
            </section>
            <!--        Valores   END-->
            <!--        Dados do motorista-->
            <section>
            <div class='container2'>
                <h2 style='text-align: center; padding: 5px;'>Dados do Grupo<br>
                </h2>
                <table>
                <tr>
                    <div class='center'>
                    <th>
                        <div>
                        <img style="width: 130px; height: auto;" src="${
                          data.group.image.url
                        }">
                        </div>
                    </th>
                    <td>
                        <h4><b>${data.group.name}</b><br><small> ${
    data.group.description
  }</small></h4>
                        ${
                          data.group.franchise &&
                          `Franquia ${data.group.franchise.franchise}`
                        }
                    </td>
                    </div>
                </tr>
                </table>
            </div>
            </section>
            <!--        Dados do motorista END-->
                                  
            <section>
            <div class='container3'>
            <h2 style='padding: 20px; color: white;'>3S aluguéis
            </h2>
            <hr>
            </div>
        </section>
        </body>
    </html> `;

  let body = {
    subject: `Confirmação da reserva Nº${data.id}`,
    targetEmail: "empresa3s@hotmail.com",
    targetName: "Romildo",
    plainTextContent: message,
    htmlContent: message,
  };

  let seccondBody = {
    subject: `Confirmação da reserva Nº${data.id}`,
    targetEmail: user.emails[0],
    targetName: user.given_name,
    plainTextContent: message,
    htmlContent: message,
  };

  SendEmail(body, (res) => {});
  SendEmail(seccondBody, (res) => {});
}
