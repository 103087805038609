import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import BaseService from "@/app/core/base/BaseService";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";
import OptionalCard from "../../core/layouts/components/OptionalCard";
import ReservationCard from "@/app/core/layouts/main-components/ReservationCard";

export default function RentComponent(props) {
  const [kickOut, setKickOut] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [services, setServices] = useState(null);
  const [insurances, setInsurances] = useState(null);
  const [data, setData] = useState(Storage.getSOData());

  useEffect(() => {
    loadServices();
    loadInsurances();
  }, []);

  function reloadData() {
    setData(Storage.getSOData());
  }

  function loadServices() {
    BaseService(
      {
        method: "GET",
        url: "services/",
      },
      (res) => {
        setServices(res);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  function loadInsurances() {
    BaseService(
      {
        method: "GET",
        url: "insurances/",
      },
      (res) => {
        setInsurances(res);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  function goHome() {
    window.location = "#/";
  }

  function goVehicle() {
    setRedirect("/rent");
  }

  function goClosure() {
    setRedirect("/closure");
  }

  function handleServices(item) {
    let infos = data;
    let serviceList = infos.services !== undefined ? infos.services : [];
    let newList = [];

    let i = serviceList.filter((x) => x.id === item.id);
    if (i.length < 1 && item.selected === true) {
      newList.push(item);
    }

    for (let s in serviceList) {
      if (serviceList[s].id === item.id) {
        if (item.selected === true) {
          newList.push(serviceList[s]);
        }
      } else {
        newList.push(serviceList[s]);
      }
    }

    if (newList.length < 1 && item.selected === true) {
      newList.push(item);
    }

    setData({ ...infos, services: newList });
    allServicesHandle(item);
  }

  function allServicesHandle(item) {
    let itens = services;

    for (let s in itens) {
      if (item.id === itens[s].id) {
        itens[s].selected = item.selected;
      } else {
        itens[s].selected = false;
      }
    }

    console.log("servicos", itens);
    setServices(itens);
  }

  function handleInsurances(item) {
    let infos = data.insurances;
    let insuranceList = infos !== undefined ? infos : [];
    let newList = [];

    let i = insuranceList.filter((x) => x.id === item.id);
    if (i.length < 1 && item.selected === true) {
      newList.push(item);
    }

    for (let s in insuranceList) {
      if (insuranceList[s].id === item.id) {
        if (item.selected === true) {
          newList.push(insuranceList[s]);
        }
      } else {
        newList.push(insuranceList[s]);
      }
    }

    if (newList.length < 1 && item.selected === true) {
      newList.push(item);
    }

    setData({ ...data, insurances: newList });
  }

  function goNext() {
    if (data.insurances === undefined) {
      toast.error("Você deve selecionar ao menos um seguro");
    } else {
      Storage.setSOData(data);
      window.location = "#/closure";
    }
  }

  if (redirect) return <Redirect to={`${redirect}`} />;
  if (kickOut) return <Redirect to="/" />;

  return (
    <div>
      <div className="row bg-warning" style={{ height: 205 }}>
        <div className="block-content">
          <div className="col-md-12 py-6 ml-3 mt-5">
            <div className="btn-warning">
              <button
                className="btn btn-sm btn-square btn-warning"
                onClick={() => {
                  goHome();
                }}
              >
                1. Itinerário
              </button>
              <button
                className="btn btn-sm btn-square btn-warning"
                onClick={() => {
                  goVehicle();
                }}
              >
                2. Escolha seu veículo
              </button>
              <button
                className="btn btn-sm btn-square btn-warning"
                disabled={true}
              >
                3. Proteção e opcionais
              </button>
              <button
                className="btn btn-sm btn-square btn-warning"
                onClick={() => {
                  goClosure();
                }}
              >
                4. Fechar reserva
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <h2>Escolha sua proteção e opicionais</h2>
        <div className="row">
          <div className="col-md-9">
            <h4>
              Serviços
              <br />
              <small>
                Adicione serviços e aumente as possibilidades do seu plano 3S.
              </small>
            </h4>
            {services &&
              services.map((item) => (
                <OptionalCard
                  data={item}
                  selecteds={item.selected}
                  type={1}
                  selected={(data) => handleServices(data)}
                />
              ))}
            <br />
            <h4>
              Proteção
              <br />
              <small>
                Para você ter uma locação tranquila, a 3S tem como pré-requisito
                a proteção básica.
              </small>
            </h4>
            {insurances &&
              insurances.map((item) => (
                <OptionalCard
                  data={item}
                  type={2}
                  selecteds={item.selected}
                  selected={(data) => handleInsurances(data)}
                />
              ))}
          </div>
          <div className="col-md-3">
            <ReservationCard
              data={data}
              next={() => goNext()}
              reload={() => reloadData()}
              enableNext={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
