import React from "react";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import MainComponent from "./main/index";
import Layout from "@/app/modules/website/core/layouts";
import Storage from "@/app/core/util/Storage";

const ProjectShell = props => <Layout {...props}>{renderRoutes(props.route.routes, props)}</Layout>;

const Redirecter = () => <Redirect to="/error/404" />;

export const OptionalsRouter = {
  path: "/optionals",
  component: ProjectShell,
  routes: [
    {
      path: "/optionals",
      exact: true,
      component: MainComponent
    },
    {
      path: "/optionals/search",
      exact: true,
      component: MainComponent
    },
    {
      path: "*",
      component: Redirecter
    }
  ]
};
