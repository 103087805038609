import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState } from "react";
import BaseService from "@/app/core/base/BaseService";
import Modal from "@/app/core/layouts/main/components/Modal";
import { InsertCreditCard } from "../../base/controllers/CreditCardController";

export default function CreditCardModal({ show, onHide }, props) {
  const [data, setData] = useState({});
  const userData = Storage.getUserData();

  useEffect(() => {}, []);

  function save() {
    let card = data;

    if (
      validate(card.number) &&
      validate(card.holder) &&
      validate(card.expiration) &&
      validate(card.securityCode)
    ) {
      const parts = card.expiration.split("/");
      const month = parts[0];
      const year = parts[1];

      card.expirationMonth = month;
      card.expirationYear = year;
      card.userId = userData.idToken.objectId;

      InsertCreditCard(card, (res) => {
        toast.success("Salvo com sucesso");
        onHide();
      });
    } else {
      toast.error("Preencha todos os dados para poder salvar");
    }
  }

  function validate(info) {
    if (info === undefined || info === null || info === "") {
      return false;
    } else {
      return true;
    }
  }

  function onChange(evt) {
    let newInfo = { [evt.target.name]: evt.target.value };
    setData({ ...data, ...newInfo });
  }

  return (
    <Modal
      title="Novo cartão de crédito"
      size={"md"}
      show={show}
      onHide={onHide}
      onSave={() => save()}
      onSubmit={() => save()}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <span>Número do cartão</span>
            <InputMask
              mask="9999-9999-9999-9999"
              maskChar=""
              type="text"
              className="form-control"
              id="advanced-filter-document"
              name="number"
              placeholder="XXXX-XXXX-XXXX-XXXX"
              onChange={(event) => {
                onChange({
                  target: {
                    value: event.target.value.replace(/\D/g, ""),
                    name: "number",
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <span>Data de vencimento</span>
            <InputMask
              mask="99/99"
              maskChar=""
              type="text"
              className="form-control"
              id="advanced-filter-document"
              name="mes"
              placeholder="Mês/Ano"
              onChange={(event) => {
                onChange({
                  target: { value: event.target.value, name: "expiration" },
                });
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <span>Cód. Segurança</span>
            <input
              type="text"
              className="form-control"
              name="securityCode"
              onChange={(evt) => onChange(evt)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <span>Nome impresso no cartão</span>
            <input
              type="text"
              className="form-control"
              name="holder"
              onChange={(evt) => onChange(evt)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
