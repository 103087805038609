export function ChargeTypeTransate(type) {
  if (type === 0) {
    return "Dia";
  }
  if (type === 1) {
    return "Mês";
  }
  if (type === 2) {
    return "única";
  }
}

export function CardMask(text) {
  const ddi = text.slice(0, 4);
  const ddd = text.slice(4, 8);
  const cel1 = text.slice(8, 12);
  const cel2 = text.slice(12, 16);

  const organized = `${ddi}-${ddd}-${cel1}-${cel2}`;
  return organized;
}
