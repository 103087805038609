import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";

export function GetGroups(response) {
  BaseService(
    {
      method: "GET",
      url: "vehicle-groups",
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error("Ocorreu um erro ao consultar os grupos");
    }
  );
}
