import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Storage from "@/app/core/util/Storage";
import Logo from "./Logo";
import { AzureAD, LoginType, AuthenticationState } from "react-aad-msal";
import { authProvider } from "@/app/core/config/AuthProvider";
import { Json } from "../../config/menu";

export default function Header(props) {
  const [notification, setNotification] = useState([]);
  const [userData, setUserData] = useState(Storage.getUserData());
  const [showModal, setShowModal] = useState(false);
  const [username, setUsername] = useState(``);

  function toggleModal() {
    setShowModal(!showModal);
  }

  useEffect(() => {
    if (userData === null) {
      if (
        authProvider.authenticationState !== AuthenticationState.Unauthenticated
      ) {
        authProvider.getIdToken().then((data) => {
          console.log("usuario", data);
          if (data !== null && data !== undefined) {
            Storage.setUserData(data);
            window.location.reload();
          }
        });
      }
    } else {
      setUsername(userData.idToken.claims.given_name);
    }
  }, []);

  function logout() {
    localStorage.removeItem("user_data");
    authProvider.logout();
  }

  function goContacts() {
    window.location = `#/contacts`;
  }

  function goAccount() {
    window.location = `#/account`;
  }

  function goReservations() {
    window.location = `#/reservations`;
  }

  return (
    <header id="page-header">
      <div className="row p-2">
        <div className="col-7">
          <div className="row">
            <div className="col-1 col-sm-1">
              <Dropdown>
                <Dropdown.Toggle className="bg-warning mt-3">
                  <i className="fa fa-fw fa-bars text-white" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <ul className="nav-main">
                    {Json.map((item) => (
                      <li className="nav-main-item">
                        <a
                          className="nav-main-link"
                          href={`#/${item.routerLink}`}
                        >
                          <i
                            className={`nav-main-link-icon si ${item.icon}`}
                          ></i>
                          <span className="nav-main-link-name">
                            {item.name}
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
              {/* <button
                type="button"
                className="btn btn-link mr-1"
                onClick={() => props.toggleNav()}
              >
                <i className="fa fa-fw fa-bars text-white" />
              </button> */}
            </div>
            <div className="col-md-11 col-sm-11 text-left">
              <Logo style={{ width: 180 }} className="ml-2" />
            </div>
          </div>
        </div>
        <div className="col-5">
          <div>
            {userData && userData !== undefined ? (
              <div>
                <div className="row mt-3">
                  <div className="col-md-7 text-right">
                    <button
                      type="button"
                      className="btn btn-square btn-warning mr-2"
                      onClick={() => goContacts()}
                    >
                      <i className="si si-call-in"></i> Contato
                    </button>
                  </div>
                  <div className="col-md-5 text-right">
                    <div className="dropdown d-inline-block">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant={Storage.getTheme()}
                          id="dropdown-basic"
                          className="btn btn-square btn-secondary"
                        >
                          Olá {username}!
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            eventKey="1"
                            className="dropdown-item"
                            onClick={() => goAccount()}
                          >
                            <i className="far fa-fw fa-user mr-1" /> Minha conta
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="2"
                            onClick={() => goReservations()}
                            className="dropdown-item d-flex align-items-center justify-content-between"
                          >
                            <i className="far fa-fw fa-calendar mr-1" /> Minhas
                            reservas
                          </Dropdown.Item>
                          {/* <Dropdown.Item eventKey="3" className="dropdown-item">
                      <i className="far fa-fw fa-file-alt mr-1" /> Meu
                      histórico
                    </Dropdown.Item> */}
                          <Dropdown.Divider />
                          <Dropdown.Item
                            eventKey="5"
                            className="dropdown-item"
                            onClick={() => logout()}
                          >
                            <i className="far fa-fw fa-arrow-alt-circle-left mr-1" />{" "}
                            Sair
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="row mt-3">
                  <div className="col-md-7 text-right">
                    <button
                      className="btn btn-square btn-warning"
                      onClick={() => goContacts()}
                    >
                      <i className="si si-call-in"></i> Contato
                    </button>
                  </div>
                  <div className="col-md-5 text-right">
                    <button
                      className="Button btn btn-square btn-secondary"
                      onClick={() => authProvider.loginRedirect()}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="content-header"></div>

      <div id="page-header-loader" className="overlay-header bg-primary-darker">
        <div className="content-header">
          <div className="w-100 text-center">
            <i className="fa fa-fw fa-2x fa-sun fa-spin text-white" />
          </div>
        </div>
      </div>
      {/* <Modal
        data={selectedData}
        info={selectedData}
        show={showModal}
        submit={(updateBudget) => null}
        onHide={() => setShowModal(false)}
        
      /> */}
    </header>
  );
}
