import React, { useState, useEffect } from "react";
import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import RentCard from "../../core/layouts/components/RentCard";
import MapsComponent from "@/app/core/layouts/main-components/MapComponent";
import { GetUnities } from "@/app/core/base/controllers/UnitiesController";

export default function RentComponent(props) {
  const [stores, setStores] = useState([]);
  const [query, setQuery] = useState("");
  const [place, setPlace] = useState({});
  const [lat, setLat] = useState(-23.854197233100773);
  const [lng, setLng] = useState(-46.39760051261076);
  const [zoom, setZoom] = useState(10);

  useEffect(() => {
    loadStores();
  }, []);

  useEffect(() => {
    console.log("place", place);
    if (place.latitude !== undefined && place.latitude !== null) {
      showPlace(place);
    }
  }, [place]);

  function loadStores() {
    GetUnities((res) => {
      setStores(res);
      console.log(res);
    });
  }

  function showPlace(store) {
    console.log("dados", store);
    // setTimeout(() => {
    setLat(store.latitude);
    setLng(store.longitude);
    setZoom(14);
    // }, 1000);
  }

  return (
    <div>
      <div className="row bg-warning" style={{ height: 300 }}>
        <div className="block-content">
          <div className="col-md-12 py-6">
            <RentCard />
          </div>
        </div>
      </div>
      <div className="content">
        <h2 className="text-warning">Conheça Lojas 3S</h2>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <Typeahead
                options={stores}
                labelKey={(option) => `${option.address} - ${option.city}`}
                onSearch={(q) => setQuery(q)}
                id="advanced-filter-state"
                name="name"
                placeholder="Digite a loja desejada"
                onChange={(selected) => {
                  if (selected[0] === undefined) {
                  } else {
                    setPlace(selected[0]);
                  }
                }}
              />
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <MapsComponent
              latitude={lat}
              longitude={lng}
              cameraZoom={zoom}
              places={stores}
            />
          </div>
          <div className="col-md-6">
            {stores &&
              stores.map((store) => (
                <div
                  className="block block-rounded border-left border-warning border-5x block-fx-pop mb-2"
                  onClick={() => setPlace(store)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="block-header">
                    <div className="block-title">
                      {store.address}
                      <br />
                      {store.city} - {store.state}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
