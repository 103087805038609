import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";

export function InsertCreditCard(creditCard, response) {
  BaseService(
    {
      method: "POST",
      url: "credit-card",
      data: creditCard,
    },
    (res) => {
      return response(res);
    },
    (err) => {
      toast.error("Ocorreu um erro ao adicionar o cartão");
    }
  );
}

export function UpdateCreditCard(creditCard, response) {
  BaseService(
    {
      method: "POST",
      url: "update-credit-card",
      data: creditCard,
    },
    (res) => {
      return response(res);
    },
    (err) => {
      toast.error("Ocorreu um erro ao atualizar o cartão");
    }
  );
}

export function ListUserCreditCard(userId, response) {
  BaseService(
    {
      method: "GET",
      url: `credit-cards/${userId}`,
    },
    (res) => {
      return response(res);
    },
    (err) => {
      toast.error("Ocorreu um erro ao atualizar o cartão");
    }
  );
}

export function RemoveCreditCard(cardId, response) {
  BaseService(
    {
      method: "DELETE",
      url: `credit-cards/${cardId}`,
    },
    (res) => {
      return response(res);
    },
    (err) => {
      toast.error("Ocorreu um erro ao atualizar o cartão");
    }
  );
}
