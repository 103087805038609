import React, { useState, useEffect } from "react";
import Logo from "../../core/layouts/components/Logo";
import RentCard from "../../core/layouts/components/RentCard";

export default function RentComponent() {
  useEffect(() => {}, []);

  return (
    <div>
      <div className="row bg-warning" style={{ height: 300 }}>
        <div className="block-content">
          <div className="col-md-12 py-6">
            <RentCard />
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="block block-fx-pop bg-gd-sun">
              <div className="row">
                <div className="col-md-6">
                  <img
                    src="https://3salugueis.blob.core.windows.net/files/test/8da5ca4a-898c-4690-b128-66e580c20ac0.png"
                    style={{ height: 250 }}
                  />
                </div>
                <div className="col-md-6">
                  <div className="py-3 text-center">
                    <Logo />
                    <h2 className="text-white">Conheça quem somos</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <p>
              Somos uma empresa especializada em aluguéis de veículos no Brasil
              cediados no litoral de São Paulo.
              <br />
              <br />
              <h3 className="text-warning">Nossa missão</h3>
              Nossa proposta é repensar o conceito de mobilidade no Brasil, você
              não precisa se preocupar com os custos naturais de se ter um
              veículo. Do nosso ponto de vista a Mobilidade tem tudo a ver com a
              liberdade, Mesmo que cada um tenha uma visão diferente sobre ela,
              a nossa missão é oferecer um meio de ir e vir em suas viagens sem
              preocupações.
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
