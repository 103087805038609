import React, { useState, useEffect } from "react";
import BaseService from "@/app/core/base/BaseService";
import Storage from "@/app/core/util/Storage";
import { Redirect } from "react-router-dom";
import ReservationCard from "@/app/core/layouts/main-components/ReservationCard";
import GroupCard from "@/app/core/layouts/main-components/GroupCard";
import { toast } from "react-toastify";

export default function RentComponent(props) {
  const [kickOut, setKickOut] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [data, setData] = useState(Storage.getSOData());
  const [groups, setGroups] = useState([]);
  const [franchises, setFranchises] = useState([]);

  useEffect(() => {
    loadFranchises();
  }, []);

  function reloadData() {
    setData(Storage.getSOData());
  }

  function loadFranchises() {
    BaseService(
      {
        method: "GET",
        url: "franchises/",
      },
      (res) => {
        setFranchises(res);
        console.log("franquias", res);
        loadGroups();
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  function loadGroups() {
    BaseService(
      {
        method: "GET",
        url: "vehicle-groups/",
      },
      (res) => {
        setGroups(res);
        console.log(`Grupos`, res);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  function goHome() {
    window.location = "#/";
  }

  function goOptional() {
    setRedirect("/optionals");
  }

  function selectGroup(element) {
    let infos = { ...data, group: element };
    Storage.setSOData(infos);
    setData(infos);
    setTimeout(() => {
      goOptional();
    }, 500);
  }

  if (redirect) return <Redirect to={`${redirect}`} />;
  if (kickOut) return <Redirect to="/" />;

  return (
    <div>
      <div className="row bg-warning" style={{ height: 205 }}>
        <div className="block-content">
          <div className="col-md-12 py-6 ml-3 mt-5">
            <div className="bg-warning">
              <button
                className="btn btn-sm btn-square btn-warning"
                onClick={() => {
                  goHome();
                }}
              >
                1. Itinerário
              </button>
              <button
                className="btn btn-sm btn-square btn-warning"
                disabled={true}
              >
                2. Escolha seu veículo
              </button>
              <button className="btn btn-sm btn-square btn-warning">
                3. Proteção e opcionais
              </button>
              <button className="btn btn-sm btn-square btn-warning">
                4. Fechar reserva
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <h2>Escolha seu veículo</h2>
        <div className="row">
          <div className="col-md-9">
            {groups &&
              groups.map((item) => (
                <GroupCard
                  key={`group-${item && item.id}`}
                  data={item}
                  franchises={franchises}
                  selected={(element) => selectGroup(element)}
                />
              ))}
          </div>
          <div className="col-md-3">
            <ReservationCard data={data} reload={() => reloadData()} />
          </div>
        </div>
      </div>
    </div>
  );
}
