import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import StoreMarker from "@/app/modules/website/stores/main/StoreMarker";

export default function MapsComponent({
  places,
  latitude,
  longitude,
  cameraZoom,
}) {
  const [data, setData] = useState({});
  const [lat, setLat] = useState(-23.57);
  const [lng, setLng] = useState(-46.68);
  const [zoom, setZoom] = useState(10);

  useEffect(() => {
    console.log("action", latitude, longitude, cameraZoom, places);
    if (latitude) {
      setLat(latitude);
      setLng(longitude);
    }

    setData({ places: places });
    setZoom(cameraZoom);
  }, [places, latitude, longitude]);

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };

  return (
    <div>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAjHY1raiZKuwktiRYHUyqj7RVFeNrl6FQ" }}
        defaultCenter={{
          lat: -23.57,
          lng: -46.68,
        }}
        style={{ height: 450 }}
        zoom={zoom}
        center={{
          lat: lat,
          lng: lng,
        }}
        defaultZoom={10}
      >
        {data.places &&
          data.places.map((item) => (
            <StoreMarker
              lat={item.latitude}
              lng={item.longitude}
              key={item.id}
              click={() => {
                alert(item.address);
              }}
            />
          ))}
      </GoogleMapReact>
    </div>
  );
}
