import React, { useState, useEffect } from "react";
import BaseService from "@/app/core/base/BaseService";
import Storage from "@/app/core/util/Storage";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import RentCard from "../../core/layouts/components/RentCard";
import PostCard from "../../core/layouts/components/PostCard";
import BannerComponent from "@/app/core/layouts/main-components/BannerComponent";

export default function MainComponent(props) {
  const [kickOut, setKickOut] = useState(false);
  const [posts, setPosts] = useState(null);
  const [date, setDate] = useState(null);

  useEffect(() => {
    loadPosts();
    localStorage.removeItem("so_data");
  }, []);

  function loadPosts() {
    //   setPosts([
    //     {
    //       url : process.env.PUBLIC_URL + "/assets/media/mapa.jpg"
    //     },
    // ])
    BaseService(
      {
        method: "GET",
        url: "posts/",
      },
      (res) => {
        setPosts(res);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  if (kickOut) return <Redirect to="/" />;

  return (
    <div>
      <BannerComponent />
      <div className="row">
        <div className="col-md-12" style={{ marginTop: -190 }}>
          <RentCard />
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-md-4">
            <div className="block block-rounded text-left">
              <div className="text-dark py-2">Nossas lojas</div>
              <div
                className="block-content block-content-full bg-image"
                style={{
                  height: 130,
                  backgroundImage:
                    "url(" +
                    process.env.PUBLIC_URL +
                    "/assets/media/mapa.jpg" +
                    ")",
                }}
              ></div>
              <div className="block-content block-content-full block-content-sm">
                <p>Procure uma loja 3S mais próxima de você</p>
                <a
                  className="form-control btn btn-square btn-warning bg-warning"
                  href="#/stores"
                >
                  Conheça
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="block block-rounded text-left">
              <div className="text-dark py-2">Frotas</div>
              <div
                className="block-content block-content-full bg-image"
                style={{
                  height: 130,
                  backgroundImage:
                    "url(" +
                    process.env.PUBLIC_URL +
                    "/assets/media/frota.png" +
                    ")",
                }}
              ></div>
              <div className="block-content block-content-full block-content-sm">
                <p>Otimize o tempo e a produtividade do seu negócio</p>
                <a
                  className="form-control btn btn-square btn-warning bg-warning"
                  href="#/fleets"
                >
                  Saiba mais
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="block block-rounded text-left">
              <div className="text-dark py-2">Institucional</div>
              <div
                className="block-content block-content-full bg-image bg-warning text-center"
                style={{
                  height: 130,
                }}
              >
                <img src={process.env.PUBLIC_URL + "/assets/media/logo.png"} />
              </div>
              <div className="block-content block-content-full block-content-sm">
                <p>Conheça a 3S e nossa visão</p>
                <a
                  className="form-control btn btn-square btn-warning bg-warning"
                  href="#/institutional"
                >
                  Conheça
                </a>
              </div>
            </div>
          </div>

          {/* {posts && posts.map((item) => <PostCard data={item} />)} */}
        </div>
      </div>
    </div>
  );
}
