import React from "react";

export default function CreditCard(props) {
  return (
    <img
      src={process.env.PUBLIC_URL + "/assets/media/credit_card.png"}
      alt="Logo Status"
      {...props}
    />
  );
}
