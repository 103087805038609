import React, { useEffect, useState } from "react";
import BaseService from "@/app/core/base/BaseService";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import { MoneyToString } from "@/app/core/util/MoneyHelper";
import { Dropdown } from "react-bootstrap";
import GoogleDocsViewer from "react-google-docs-viewer";
import DateTranslate, {
  DateTranslateToTime,
  DateTranslateToInputDate,
} from "@/app/core/util/DateTranslater";
import PaymentItem from "./PaymentItem";

export default function ReservationModal({ show, onHide, infos }, props) {
  const [data, setData] = useState({});
  const [places, setPlaces] = useState([]);
  const [unityOriginId, setUnityOriginId] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [insurances, setInsurances] = useState([]);
  const [services, setServices] = useState([]);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (infos.id !== undefined) {
      setStartDate(DateTranslateToInputDate(infos.pickupDate));
      setEndDate(DateTranslateToInputDate(infos.dropoutDate));

      setStartTime(DateTranslateToTime(infos.pickupDate));
      setEndTime(DateTranslateToTime(infos.dropoutDate));

      setUnityOriginId(infos.unityOriginId.id);
    }
    loadPlaces();
    loadServices();
    loadInsurances();
    loadServiceOrder();
    loadPayments();
  }, [infos]);

  function loadServiceOrder() {
    console.log("tentando", infos);
    if (infos.id !== undefined) {
      BaseService(
        {
          method: "GET",
          url: `service-orders/${infos.id}`,
        },
        (res) => {
          console.log("serviceOrder", res);
          setData(res);
        },
        (err) => {
          toast.error("Erro consultar as informações. " + err);
        }
      );
    }
  }

  function loadPayments() {
    if (infos.id !== undefined) {
      BaseService(
        {
          method: "GET",
          url: `payments/serviceOrder/${infos.id}`,
        },
        (res) => {
          console.log("pagamentos", res);
          setPayments(res);
        },
        (err) => {
          toast.error("Erro consultar as informações. " + err);
        }
      );
    }
  }

  function loadPlaces() {
    BaseService(
      {
        method: "GET",
        url: "unities/",
      },
      (res) => {
        setPlaces(res);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  function loadServices() {
    BaseService(
      {
        method: "GET",
        url: "services/",
      },
      (res) => {
        console.log("skdjn", res);
        setServices(res);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  function loadInsurances() {
    BaseService(
      {
        method: "GET",
        url: "insurances/",
      },
      (res) => {
        setInsurances(res);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  function save() {
    if (startDate === null || endDate === null) {
      toast.error("Faltou responder a data de início e fim");
    } else if (unityOriginId === 0) {
      toast.error("Faltou responder o local de origem e destino");
    } else {
      let origin = places.filter((x) => x.id === parseInt(unityOriginId));

      let newData = {
        ...infos,
        pickupDate: `${startDate}T${startTime}`,
        dropoutDate: `${endDate}T${endTime}`,
        unityOriginId,
      };

      BaseService(
        {
          method: "PUT",
          url: "service-orders/",
          data: newData,
        },
        (res) => {
          loadServiceOrder();
        },
        (err) => {
          toast.error("Erro salvar os serviços. " + err);
        }
      );

      toast.info("Salvo com sucesso");
      onHide();
    }
  }

  function addService(item) {
    BaseService(
      {
        method: "POST",
        url: "selected-services/",
        data: [
          {
            serviceOrderId: data.id,
            serviceId: item.id,
          },
        ],
      },
      (res) => {
        loadServiceOrder();
      },
      (err) => {
        toast.error("Erro salvar os serviços. " + err);
      }
    );
  }

  function removeService(item) {
    if (window.confirm("Você tem certeza de que deseja excluir esse item?")) {
      BaseService(
        {
          method: "DELETE",
          url: `selected-services/${data.id}/remove-service/${item.id}`,
        },
        (res) => {
          loadServiceOrder();
        },
        (err) => {
          toast.error("Erro salvar os serviços. " + err);
        }
      );
    }
  }

  function removeInsurance(item) {
    if (window.confirm("Você tem certeza de que deseja excluir esse item?")) {
      BaseService(
        {
          method: "DELETE",
          url: `selected-insurances/${data.id}/remove-insurance/${item.id}`,
        },
        (res) => {
          loadServiceOrder();
        },
        (err) => {
          toast.error("Erro salvar os serviços. " + err);
        }
      );
    }
  }

  function showDoc(item) {
    const path = item.file.url;
    const url = `https://drive.google.com/viewerng/viewer?url=${path}?sv=2015-04-05&sr=b&sig=x8VhYOUGSNp9AJfV%2BT%2BybcBkPQknqNXdolq4Xo0ZAXU%3D&se=2016-06-29T20%3A58%3A52Z&sp=r&embedded=true`;

    window.open(url, "_blank");
  }

  function downloadDoc(item) {
    let url = item.file.url;
    window.open(url, "_blank");
  }

  function addInsurance(item) {
    BaseService(
      {
        method: "POST",
        url: "selected-insurances/",
        data: [
          {
            serviceOrderId: data.id,
            insuranceId: item.id,
          },
        ],
      },
      (res) => {
        loadServiceOrder();
      },
      (err) => {
        toast.error("Erro salvar os serviços. " + err);
      }
    );
  }

  return (
    <Modal
      title="Editar Reserva"
      size={"lg"}
      show={show}
      onHide={onHide}
      onSave={() => save()}
      onSubmit={() => save()}
    >
      <Tabs>
        <Tab>
          <button className="btn btn-dual">Reserva</button>
        </Tab>
        <Tab>
          <button className="btn btn-dual">Contrato</button>
        </Tab>
        <Tab>
          <button className="btn btn-dual">Financeiro</button>
        </Tab>
        <Panel>
          <div className="block block-fx-pop">
            <div className="block-header">
              <div className="block-title font-w700">Itinerário</div>
            </div>
            <div className="block-content">
              <div className="form-group">
                <span>Onde você quer alugar?</span>
                <select
                  className="form-control"
                  onChange={(evt) => setUnityOriginId(evt.target.value)}
                >
                  <option value={null}>Selecione</option>
                  {places &&
                    places.map((item) => (
                      <option
                        value={item.id}
                        selected={
                          item.id === infos.unityOriginId ? true : false
                        }
                      >
                        {item.address}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group">
                <span>Data e hora da retirada</span>
                <div
                  className="input-group form-control form-control-alt"
                  style={{ padding: 0, margin: 0 }}
                >
                  <div className="col-7" style={{ padding: 0, margin: 0 }}>
                    <input
                      type="date"
                      className="form-control form-control-alt"
                      value={startDate}
                      onChange={(evt) => setStartDate(evt.target.value)}
                    />
                  </div>
                  <div className="col-5" style={{ padding: 0, margin: 0 }}>
                    <input
                      type="time"
                      className="form-control form-control-alt"
                      value={startTime}
                      onChange={(evt) => setStartTime(evt.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <span>Data e hora da devolução</span>
                <div
                  className="input-group form-control form-control-alt"
                  style={{ padding: 0, margin: 0 }}
                >
                  <div className="col-7" style={{ padding: 0, margin: 0 }}>
                    <input
                      type="date"
                      className="form-control form-control-alt"
                      value={endDate}
                      onChange={(evt) => setEndDate(evt.target.value)}
                    />
                  </div>
                  <div className="col-5" style={{ padding: 0, margin: 0 }}>
                    <input
                      type="time"
                      className="form-control form-control-alt"
                      value={endTime}
                      onChange={(evt) => setEndTime(evt.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block block-fx-pop">
            <div className="block-header">
              <div className="block-title font-w700">Serviços</div>
              <div className="block-tools">
                <Dropdown>
                  <Dropdown.Toggle variant={"light"}>
                    <button className="btn btn-link">Adicionar serviços</button>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ width: 550 }}>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <td>Valor</td>
                          <td>Serviço</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {services &&
                          services.map((item) => (
                            <tr>
                              <td>R${MoneyToString(item.price, 2)}</td>
                              <td>
                                <label className="font-w700">{item.name}</label>
                                <br /> {item.description}
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-dual"
                                  onClick={() => addService(item)}
                                >
                                  Adicionar
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="block-content">
              <table className="table">
                <thead>
                  <th>Valor</th>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th></th>
                </thead>
                {data.selectedServices &&
                  data.selectedServices.map((item) => (
                    <tr>
                      <td>R${MoneyToString(item.price, 2)}</td>
                      <td>{item.name}</td>
                      <td>{item.description}</td>
                      <td>
                        <button
                          className="btn btn-link text-danger"
                          onClick={() => removeService(item)}
                        >
                          <i className="si si-close"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </table>
            </div>
          </div>
          <div className="block block-fx-pop">
            <div className="block-header">
              <div className="block-title font-w700">Seguros</div>
              <div className="block-tools">
                <Dropdown>
                  <Dropdown.Toggle variant={"light"}>
                    <button className="btn btn-link">Adicionar seguro</button>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ width: 550 }}>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <td>Valor</td>
                          <td>Serviço</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {insurances &&
                          insurances.map((item) => (
                            <tr>
                              <td>R${MoneyToString(item.price, 2)}</td>
                              <td>
                                <label className="font-w700">{item.name}</label>
                                <br /> {item.description}
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-dual"
                                  onClick={() => addInsurance(item)}
                                >
                                  Adicionar
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="block-content">
              <table className="table">
                <thead>
                  <th>Valor</th>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th></th>
                </thead>
                {data.selectedInsurances &&
                  data.selectedInsurances.map((item) => (
                    <tr>
                      <td>R${MoneyToString(item.price, 2)}</td>
                      <td>{item.name}</td>
                      <td>{item.description}</td>
                      <td>
                        <button
                          className="btn btn-link text-danger"
                          onClick={() => removeInsurance(item)}
                        >
                          <i className="si si-close"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </table>
            </div>
          </div>
        </Panel>
        <Panel>
          {infos.contractFile && infos.contractFile !== null ? (
            <div className="py-4 block block-fx-pop">
              <div className="block-header">
                <div className="block-title">
                  {infos.contractFile && infos.contractFile.name} -{" "}
                  {infos.contractFile &&
                    DateTranslate(infos.contractFile.creationDate)}
                </div>
                <div className="block-tools">
                  <button
                    className="btn btn-sm btn-link text-dark"
                    onClick={() => showDoc({ files: infos.contractFile })}
                  >
                    <i className="far fa-eye"></i>
                  </button>
                  <button
                    className="btn btn-sm btn-link text-dark"
                    onClick={() => downloadDoc({ files: infos.contractFile })}
                  >
                    <i className="fa fa-download"></i>
                  </button>
                </div>
              </div>
              <GoogleDocsViewer
                width="100%"
                height={680}
                fileUrl={infos.contractFile.url}
              />
            </div>
          ) : (
            <div className="text-center py-4">Nenhum contrato adicionado</div>
          )}
        </Panel>
        <Panel>
          <div className="block block-fx-pop">
            <div className="block-header">
              <div className="block-title font-w700">Pagamentos</div>
            </div>
            <div className="block-content">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <td>Data de pagamento</td>
                    <td>Descrição</td>
                    <td>Valor</td>
                    <td>Status</td>
                    <td>Cobrança / Boleto</td>
                    <td>Comprovante de pagamento</td>
                  </tr>
                </thead>
                <tbody>
                  {payments &&
                    payments.map((item) => (
                      <PaymentItem data={item} reload={() => loadPayments()} />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Panel>
      </Tabs>
    </Modal>
  );
}
