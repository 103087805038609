import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import BaseService from "@/app/core/base/BaseService";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";
import ReservationCard from "@/app/core/layouts/main-components/ReservationCard";
import DateTranslate, {
  DateTranslateToInput,
  Datediference,
} from "@/app/core/util/DateTranslater";
import TermsAndConditionsModal from "@/app/core/layouts/main-components/TermsAndConditionsModal";
import { AzureAD, LoginType, AuthenticationState } from "react-aad-msal";
import { authProvider } from "@/app/core/config/AuthProvider";
import { MoneyToString } from "@/app/core/util/MoneyHelper";
import {
  ProcessPayments,
  ProcessTotalValue,
} from "@/app/core/util/helpers/Payments";
import { Dropdown } from "react-bootstrap";
import { InsertPayment } from "@/app/core/base/controllers/PaymentController";
import { ReservationEmail } from "@/app/core/util/helpers/EmailHelper";
import { ListUserCreditCard } from "@/app/core/base/controllers/CreditCardController";

export default function ClosureComponent(props) {
  const [kickOut, setKickOut] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [data, setData] = useState(Storage.getSOData());
  const [confirm, setConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const userData = Storage.getUserData();
  const [creditCards, setCreditCards] = useState([]);
  const [now, setNow] = useState(false);

  const [days, setDays] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalOff, setTotalOff] = useState(0);

  useEffect(() => {
    let date = DateTranslateToInput(data.startDate);
    let date2 = DateTranslateToInput(data.endDate);
    let res = Datediference(new Date(date), new Date(date2));
    setDays(res);
    if (userData !== null) {
      loadUserCards();
    }

    if (data.group) {
      setNow(data.group.payNow);
    }
  }, []);

  useEffect(() => {
    let servicesList = data.services !== undefined ? data.services : [];
    let insurancesList = data.insurances !== undefined ? data.insurances : [];
    let results = ProcessTotalValue(data, servicesList, insurancesList, days);
    setTotal(results);
    let dicounts = (results * 3) / 100;
    setTotalOff(results - dicounts);
  }, [data, days]);

  function goHome() {
    window.location = "#/";
  }

  function goVehicle() {
    setRedirect("/rent");
  }

  function goOptionals() {
    setRedirect("/optionals");
  }

  function goClosure() {
    setRedirect("/closure");
  }

  function goReservations() {
    window.location = `#/reserved`;
  }

  function goNext() {}

  function loadUserCards() {
    ListUserCreditCard(userData.idToken.objectId, (res) => setCreditCards(res));
  }

  function finish() {
    if (userData === null) {
      toast.error("Faça login para continuar");
    } else {
      let body = {
        userId: userData.idToken.objectId,
        pickupDate: `${data.startDate}T${data.startTime}`,
        dropoutDate: `${data.endDate}T${data.endTime}`,
        unityOriginId: data.unityOriginId.id,
        unityDropoutId: data.unityDropoutId.id,
        vehicleGroupId: data.group && data.group.id,
        franchiseId: data.group && data.group.franchiseId,
        vehicleId: 5,
        estimatedPrice: total,
        status: 1,
        payments: [],
      };

      BaseService(
        {
          method: "POST",
          url: "service-orders/",
          data: body,
        },
        (res) => {
          saveServices(res);
          saveInsurances(res);
          toast.info("Salvo com sucesso");

          savePayments(res);
          let updatedData = { ...data, id: res.id, value: total };
          Storage.setSOData(updatedData);
          ReservationEmail(updatedData, userData);
        },
        (err) => {
          if (err.response === null || err.response === undefined) {
            toast.error("Erro, tente novamente mais tarde. " + err);
          } else if (err.response.status === 400) {
            let info = "";

            for (let s in err.response.data.err) {
              info += " " + err.response.data.err[s];
            }

            toast.error(info);
          } else if (err.response.status === 401) {
            toast.error("Sua sessão expirou. Faça login novamenta");
          }
        }
      );
    }
  }

  function savePayments(response) {
    let payments = ProcessPayments(data, data.services, data.insurances);

    payments.forEach((payment) => {
      InsertPayment({ ...payment, serviceOrderId: response.id }, (res) => {});
    });
    setTimeout(() => {
      goReservations();
    }, 2000);
  }

  function reloadData() {
    setData(Storage.getSOData());
  }

  function definePayment(payNow) {
    let infos = data;
    infos.group.payNow = payNow;
    Storage.setSOData(infos);
    setNow(payNow);
    setData(infos);
  }

  function saveServices(res) {
    if (data.services !== undefined) {
      for (let s in data.services) {
        let item = data.services[s];
        BaseService(
          {
            method: "POST",
            url: "selected-services/",
            data: [
              {
                serviceOrderId: res.id,
                serviceId: item.id,
              },
            ],
          },
          (res) => {},
          (err) => {
            toast.error("Erro salvar os serviços. " + err);
          }
        );
      }
    }
  }

  function saveInsurances(res) {
    if (data.insurances !== undefined) {
      for (let s in data.insurances) {
        let item = data.insurances[s];
        BaseService(
          {
            method: "POST",
            url: "selected-insurances/",
            data: [
              {
                serviceOrderId: res.id,
                insuranceId: item.id,
              },
            ],
          },
          (res) => {},
          (err) => {
            toast.error("Erro salvar o seguro. " + err);
          }
        );
      }
    }
  }

  function removeService(item) {
    if (window.confirm("Tem certeza de que deseja remover esse item?")) {
      let servicesList = [];
      let newList = [];

      if (data.services !== null && data.services !== undefined) {
        servicesList = data.services;
      }

      for (let s in servicesList) {
        if (servicesList[s].id !== item.id) {
          newList.push(item);
        }
      }

      Storage.setSOData({ ...data, services: newList });
      reloadData();
    }
  }

  function removeInsurance(item) {
    if (window.confirm("Tem certeza de que deseja remover esse item?")) {
      let insuranceList = [];
      let newList = [];

      if (data.insurances !== null && data.insurances !== undefined) {
        insuranceList = data.insurances;
      }

      for (let s in insuranceList) {
        if (insuranceList[s].id !== item.id) {
          newList.push(item);
        }
      }

      Storage.setSOData({ ...data, insurances: newList });
      reloadData();
    }
  }

  function callPayment() {
    let i = ProcessPayments(data, data.services, data.insurances);
    console.log("pagamentos", i);
  }

  if (redirect) return <Redirect to={`${redirect}`} />;
  if (kickOut) return <Redirect to="/" />;

  return (
    <div>
      <div className="row bg-warning" style={{ height: 205 }}>
        <div className="block-content">
          <div className="col-md-12 py-6 mt-5">
            <div className="btn-warning">
              <button
                className="btn btn-sm btn-square btn-warning"
                onClick={() => {
                  goHome();
                }}
              >
                1. Itinerário
              </button>
              <button
                className="btn btn-sm btn-square btn-warning"
                onClick={() => {
                  goVehicle();
                }}
              >
                2. Escolha seu veículo
              </button>
              <button
                className="btn btn-sm btn-square btn-warning"
                onClick={() => {
                  goOptionals();
                }}
              >
                3. Proteção e opcionais
              </button>
              <button
                className="btn btn-sm btn-square btn-warning"
                disabled={true}
                onClick={() => {
                  goClosure();
                }}
              >
                4. Fechar reserva
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <h2>Confirmar reserva</h2>
        <div className="row">
          <div className="col-md-9">
            <div className="block block-bordered">
              <div className="block-header">
                <h3 className="block-title">Seu itinerário</h3>
              </div>
              <div className="block-content">
                <div className="row py-3">
                  <div className="col-md-6">
                    <h4>
                      <i className="fa fa-map-marker-alt text-warning"></i>{" "}
                      Retirada
                    </h4>
                    <strong>
                      {data && data.unityOriginId !== undefined
                        ? data.unityOriginId.address
                        : "S.I."}
                    </strong>
                    <div>
                      <i className="fa fa-calendar-alt"></i>{" "}
                      {data && DateTranslate(data.startDate)}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h4>
                      <i className="fa fa-map-marker-alt text-danger"></i>{" "}
                      Devolução
                    </h4>
                    <strong>
                      {data && data.unityDropoutId !== undefined
                        ? data.unityDropoutId.address
                        : "S.I."}
                    </strong>
                    <div>
                      <i className="fa fa-calendar-alt"></i>{" "}
                      {data && DateTranslate(data.endDate)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="block block-bordered">
              <div className="block-header">
                <h3 className="block-title">Serviços & seguros</h3>
                <div className="block-tools">
                  <button
                    className="btn btn-link"
                    onClick={() => (window.location = "#/optionals")}
                  >
                    Adicionar serviços e seguros
                  </button>
                </div>
              </div>
              <div className="block-content">
                <table className="table">
                  <thead>
                    <th>Tipo</th>
                    <th>Valor</th>
                    <th>Nome</th>
                    <th></th>
                    <th></th>
                  </thead>
                  {data.services &&
                    data.services.map((item) => (
                      <tr>
                        <td>Serviço</td>
                        <td>R${MoneyToString(item.price, 2)}</td>
                        <td>{item.name}</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant={"light"}
                              className="btn btn-sm btn-dual"
                            >
                              <i className="si si-plus text-warning"></i> Mais
                              informações
                            </Dropdown.Toggle>
                            <Dropdown.Menu>{item.description}</Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>
                          <button
                            className="btn btn-link text-danger"
                            onClick={() => removeService(item)}
                          >
                            <i className="si si-close"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  {data.insurances &&
                    data.insurances.map((item) => (
                      <tr>
                        <td>Seguro</td>
                        <td>R${MoneyToString(item.price, 2)}</td>
                        <td>{item.name}</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant={"light"}
                              className="btn btn-sm btn-dual"
                            >
                              <i className="si si-plus text-warning"></i> Mais
                              informações
                            </Dropdown.Toggle>
                            <Dropdown.Menu>{item.description}</Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>
                          <button
                            className="btn btn-link text-danger"
                            onClick={() => removeInsurance(item)}
                          >
                            <i className="si si-close"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                </table>
              </div>
            </div>
            <div className="block block-bordered">
              <div className="block-header">
                <div className="block-title">
                  Opções de pagamento
                  <br />
                </div>
              </div>
              <div className="block-content">
                {days > 30 ? (
                  <small>Você pagará diretamente na loja</small>
                ) : (
                  <div className="row">
                    <div
                      className={`col-md-4 p-2 m-2 block block-rounded block-bordered border-${
                        now === false ? "warning" : "seccondary"
                      } border-3x row`}
                      style={{ cursor: "pointer" }}
                      onClick={() => definePayment(false)}
                    >
                      <div className="col-md-4">
                        <i className="fa fa-store-alt text-warning py-3 fa-2x" />
                      </div>
                      <div className="col-md-8 p-2">
                        <strong>R${total && MoneyToString(total, 2)}</strong>
                        <br />
                        Pagar na loja
                      </div>
                    </div>
                    <div
                      className={`col-md-4 p-2 m-2 block block-rounded block-bordered border-${
                        now === true ? "warning" : "seccondary"
                      } border-3x row`}
                      style={{ cursor: "pointer" }}
                      onClick={() => definePayment(true)}
                    >
                      <div className="col-md-4">
                        <i className="far fa-credit-card text-warning py-3 fa-2x" />
                      </div>
                      <div className="col-md-8 p-2">
                        <strong>
                          R${totalOff && MoneyToString(totalOff, 2)}
                        </strong>
                        <br />
                        Pagar Agora
                      </div>
                    </div>
                  </div>
                )}
                {/* <h4>
                  Total :{" "}
                  <b className="text-right">
                    R${total && MoneyToString(total, 2)}
                  </b>
                </h4> */}
              </div>
            </div>
            <div className="custom-control custom-checkbox custom-control-lg custom-control-inline py-3">
              <input
                type="checkbox"
                className="custom-control-input"
                id="example-cb-custom-inline-lg1"
                name="example-cb-custom-inline-lg1"
                onChange={(evt) =>
                  setConfirm(evt.target.checked) | callPayment()
                }
              />
              <label
                className="custom-control-label"
                for="example-cb-custom-inline-lg1"
              >
                Estou ciente e de acordo com os termos de locação
              </label>
            </div>
            <br />
            <label
              className="ml-4"
              style={{ cursor: "pointer" }}
              onClick={() => setShowModal(true)}
            >
              Ler termos
            </label>
            <br />
            <br />
            <button
              type="button"
              className="btn btn-square btn-outline-warning mt-auto ml-1 mr-1 mb-2 mt-4"
              disabled={!confirm}
              onClick={() => finish()}
            >
              <i className="far fa-credit-card"></i> CONFIRMAR RESERVA
            </button>
          </div>
          <div className="col-md-3">
            <ReservationCard
              data={data}
              next={() => goNext()}
              reload={() => reloadData()}
            />
          </div>
        </div>
      </div>
      <TermsAndConditionsModal
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
}
