import React, { useState, useEffect, useRef } from "react";
import BaseService from "@/app/core/base/BaseService";
import CreditCardModal from "@/app/core/layouts/main-components/CreditCardModal";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import { Redirect } from "react-router-dom";
import {
  ListUserCreditCard,
  RemoveCreditCard,
  UpdateCreditCard,
} from "@/app/core/base/controllers/CreditCardController";
import CreditCard from "../../core/layouts/components/CreditCard";
import { CardMask } from "@/app/core/util/GeneralTranslater";

export default function MainComponent(props) {
  const [kickOut, setKickOut] = useState(false);
  const [docs, setDocs] = useState(null);
  const [processUserDocs, setProcessUserDocs] = useState(null);
  const [userDocs, setUserDocs] = useState(null);
  const [creditCards, setCreditCards] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const userData = Storage.getUserData();
  const inputFile = useRef(null);
  let docId = 0;

  useEffect(() => {
    if (userData !== null) {
      loadUserDocuments();
      loadUserCards();
    }
  }, []);

  useEffect(() => {
    processDocs();
  }, [docs]);

  function onUploadClick(id) {
    docId = id;
    inputFile.current.click();
  }

  function loadUserCards() {
    ListUserCreditCard(userData.idToken.objectId, (res) => setCreditCards(res));
  }

  function uploadFile(file) {
    if (file === undefined || file === null) {
      return;
    }

    var formData = new FormData();
    formData.append("file", file);
    BaseService(
      {
        method: "POST",
        url: `files/`,
        data: formData,
        contentType: "multipart/form-data",
      },
      (res) => {
        console.log(`chegou aqui`, res);
        createUserDoc(res[0]);
      },
      (err) => {
        toast.error(
          `Falha ao atualizar o documento, tente novamente mais tarde`
        );
      }
    );
  }

  function createUserDoc(res) {
    BaseService(
      {
        method: "POST",
        url: `user-documents`,
        data: {
          userId: userData.idToken.objectId,
          documentId: docId,
          fileId: res.id,
        },
      },
      (res) => {
        loadUserDocuments();
      },
      (err) => {
        toast.error(
          `Falha ao atualizar o documento, tente novamente mais tarde`
        );
      }
    );
  }

  function loadUserDocuments() {
    BaseService(
      {
        method: "GET",
        url: `user-documents/user/${userData.idToken.objectId}`,
      },
      (res) => {
        setUserDocs(res);
        loadDocuments(res);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  function loadDocuments(userdocs) {
    BaseService(
      {
        method: "GET",
        url: "documents/",
      },
      (res) => {
        setDocs(res);
      },
      (err) => {
        toast.error("Erro consultar as informações. " + err);
      }
    );
  }

  function processDocs() {
    let infos = docs;
    let up = [];
    for (let s in infos) {
      let item = infos[s];
      let exist = userDocs.filter((x) => x.documentId === item.id);
      console.log(`condicional`, exist, item);
      if (exist === null || exist.length < 1) {
        item.status = false;
      } else {
        item.status = true;
        item.file = exist[0].file;
      }
      up.push(item);
    }

    setProcessUserDocs(docs);
  }

  function removeCreditCard(card) {
    if (window.confirm("Deseja realmente excluir esse cartão?")) {
      RemoveCreditCard(card.id, (res) => loadUserCards());
    }
  }

  function convertPrincipal(card) {
    if (creditCards.length === 1) {
      UpdateCreditCard({ ...card, principal: true }, (res) => loadUserCards());
    } else {
      const diferents = creditCards.filter((x) => x.id !== card.id);
      UpdateCreditCard({ ...card, principal: true }, (res) => loadUserCards());
      for (let s in diferents) {
        UpdateCreditCard({ ...diferents[s], principal: false }, (res) =>
          loadUserCards()
        );
      }
    }
  }

  function verifyStatus(item, documentId) {
    console.log("arquivo?", item);
    if (item.status) {
      return (
        <p>
          {item.name} foi aprovada! Você <br /> está pronto para alugar na 3S
          <br />
          <br />
          <button
            className="btn btn-sm btn-square btn-warning"
            onClick={() => window.open(item.file.url, "_blank")}
          >
            Visualizar {item.name}
          </button>
        </p>
      );
    } else {
      return (
        <p>
          Complete seu cadastro com {item.name} e <br /> economize tempo na
          retirada do seu veículo
          <br />
          <br />
          <button
            className="btn btn-sm btn-square btn-warning"
            onClick={() => onUploadClick(documentId)}
          >
            Adicionar
          </button>
        </p>
      );
    }
  }

  if (kickOut) return <Redirect to="/" />;

  return (
    <div>
      <div style={{ height: 140, backgroundColor: "#FF6600" }}></div>
      <div className="content">
        <div className="row py-3">
          <div className="col-md-12">
            <div
              className="py-4 py-md-0 text-center text-md-left js-appear-enabled animated fadeIn"
              data-toggle="appear"
            >
              <h2 className="font-size-h2 mb-2 text-warning">Minha conta</h2>
              <hr />
            </div>
          </div>
          <div className="col-md-12">
            <div className="block block-fx-shadow">
              <div class="block-content block-content-sm block-content-full bg-body-light">
                <div class="media py-3">
                  <div class="mr-3 ml-2 overlay-container overlay-right">
                    <img
                      class="img-avatar img-avatar48"
                      src="assets/media/avatars/avatar4.jpg"
                      alt="user image"
                    />
                    <i class="far fa-circle text-white overlay-item font-size-sm bg-success rounded-circle"></i>
                  </div>
                  <div class="media-body">
                    <div class="row">
                      <div class="col-sm-7">
                        <a class="font-w600 link-fx" href="javascript:void(0)">
                          {userData && userData.idToken.claims.given_name}{" "}
                          {userData && userData.idToken.claims.family_name}
                        </a>
                        <div class="font-size-sm text-muted">
                          {userData && userData.idToken.claims.emails[0]}
                        </div>
                      </div>
                      {/* <div class="col-sm-5 d-sm-flex align-items-sm-center">
                        <div class="font-size-sm font-italic text-muted text-sm-right w-100 mt-2 mt-sm-0">
                          <p class="mb-0">March 15, 2018</p>
                          <p class="mb-0">14:25</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div
              className="py-4 py-md-0 text-center text-md-left js-appear-enabled animated fadeIn"
              data-toggle="appear"
            >
              <h3 className="font-size-h3 mb-2 text-warning">Cartões</h3>
              <hr />
            </div>
          </div>

          {creditCards &&
            creditCards.map((card) => (
              <div className="col-md-4">
                <div className="block">
                  <div className="block-content text-center py-3">
                    <CreditCard style={{ height: 190 }} />
                    <h3
                      className="text-white text-center py-3"
                      style={{ marginTop: -100 }}
                    >
                      {CardMask(card.number)}
                      <br />
                      <small>
                        {`${card.expirationMonth}/${card.expirationYear}`}
                      </small>
                    </h3>
                    <p className="mt-5">Cartão de crédito cadastrado</p>
                    <button
                      className="btn btn-sm btn-square btn-danger "
                      onClick={() => removeCreditCard(card)}
                    >
                      <i className="far fa-trash-alt" /> Excluir cartão
                    </button>
                    {card.principal === false ? (
                      <button
                        className="btn btn-sm btn-square btn-info "
                        onClick={() => convertPrincipal(card)}
                      >
                        <i className="fa fa-credit-card" /> Tornar principal
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ))}

          {creditCards.length < 1 ? (
            <div className="col-md-4">
              <div className="block">
                <div className="block-header">
                  <small>
                    {creditCards && creditCards.length < 1 ? (
                      <i className="fa fa-exclamation-circle text-danger fa-2x" />
                    ) : (
                      ""
                    )}
                  </small>
                </div>
                <div className="block-content text-center py-3">
                  <i className="far fa-credit-card fa-2x" />
                  <br />
                  <p>
                    Guarde os dados do seu cartão de crédito
                    <br /> e agilize o pagamento da sua próxima locação.
                    <br />
                    <br />
                    Será solicitado o cadastro do cartão na primeira reserva.
                    Esses dados serão armazenados em um Key Vault de alto nível
                    de segurança
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="col-md-12">
            <div
              className="py-4 py-md-0 text-center text-md-left js-appear-enabled animated fadeIn"
              data-toggle="appear"
            >
              <h3 className="font-size-h3 mb-2 text-warning">Documentos</h3>
              <hr />
            </div>
          </div>
          {processUserDocs &&
            processUserDocs.map((item) => (
              <div className="col-md-6">
                <div
                  className="py-4 py-md-0 text-center text-md-left js-appear-enabled animated fadeIn"
                  data-toggle="appear"
                >
                  <h4 className="font-size-h4 mb-2 text-warning">
                    {item.name}
                  </h4>
                  <hr />
                </div>
                <div className="block">
                  <div className="block-header">
                    <small>
                      {item.status === false && item.isRequired === true ? (
                        <i className="fa fa-exclamation-circle text-danger fa-2x" />
                      ) : (
                        ""
                      )}
                      {item.status === true ? (
                        <i className="fa fa-check text-success fa-2x" />
                      ) : (
                        ""
                      )}
                    </small>
                    {/* <div className="block-tools"> */}

                    {/* </div> */}
                  </div>
                  <div className="block-content text-center">
                    <i className="far fa-address-card text-mutted" />
                    {verifyStatus(item, item.id)}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <input
        type="file"
        style={{ visibility: "hidden" }}
        ref={inputFile}
        onChange={(event) => {
          uploadFile(event.currentTarget.files[0]);
        }}
      />

      <CreditCardModal
        show={showModal}
        onHide={() => setShowModal(false) | loadUserCards}
      />
    </div>
  );
}
